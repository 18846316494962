<template>
  <b-nav-item-dropdown right>
    <template #button-content>
      <span class="fi fi-de"></span>
    </template>
    <b-dropdown-item v-on:click="setLang('de_DE')"><span class="fi fi-de mr-1"></span>Deutsch</b-dropdown-item>
    <b-dropdown-item v-on:click="setLang('en_EN')"><span class="fi fi-eu mr-1"></span>English (EU)</b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'SprachenauswahlComponent',
  data() {
    return {
      sprachen: [
        { value: "de_DE", text: "Deutsch" },
        { value: "en_EN", text: "English" },
      ],
      open: false,
    };
  },
  mounted() {
    this.getCookie();
  },
  methods: {
    getCookie: function() {
      const einstellungen = Cookies.get('_clgmbh-trademaster-settings')

      if (einstellungen !== undefined) {
        if (einstellungen.lang !== undefined) {
          this.$i18n.locale = einstellungen.lang;
        }
      }

      if (this.$i18n.locale === "de_DE") {
        document.getElementsByClassName("fi")[0].classList.add("fi-de");

        document.getElementsByClassName("fi")[0].classList.remove("fi-eu");
      } else {
        document.getElementsByClassName("fi")[0].classList.remove("fi-de");

        document.getElementsByClassName("fi")[0].classList.add("fi-eu");
      }
    },
    setCookie: function () {
      Cookies.set(
        "_clgmbh-trademaster-settings",
        { lang: this.$i18n.locale },
        { expires: 365 }
      );
    },
    toggleSelction: function () {
      if (this.open) {
        this.open = false;
      } else {
        this.open = true;
      }
    },
    setLang: function (lang) {
      this.$i18n.locale = lang;
      this.setCookie();

      if (this.$i18n.locale === "de_DE") {
        document.getElementsByClassName("fi")[0].classList.add("fi-de");

        document.getElementsByClassName("fi")[0].classList.remove("fi-eu");
      } else {
        document.getElementsByClassName("fi")[0].classList.remove("fi-de");

        document.getElementsByClassName("fi")[0].classList.add("fi-eu");
      }
      this.toggleSelction();
    },
  },
};
</script>