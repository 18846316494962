<template>
  <div id="app" class="flex-grow-1">
    <TopNavbar></TopNavbar>
    <router-view></router-view>
  </div>
</template>

<script>
import TopNavbar from "./components/TopNavbar.vue";

export default {
  name: 'app',
  components: {
    TopNavbar,
  },
}
</script>

<style>
#content {
  margin-left: 250px;
  display: flex;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main-label {
  font-weight: 700;
}
.text-muted-light {
  color: #8a929a !important;
}
[id$="BV_tab_controls_"] {
  padding: 1rem !important;
  border-right: 1px solid #dee2e6;
}
.table th {
	border-top: none !important;
}
.custom-file-input:lang(de) ~ .custom-file-label::after {
  content: 'Durchsuchen' !important;
}
.mx-datepicker-main.mx-datepicker-popup {
  left: 50% !important;
  transform: translateX(-50%);
}
th, td, h1 {
  color: #5c5c5c;
}
</style>
