<template>
  <div class="form-row">
    <div class="col d-flex flex-column">
      <b-card no-body>
        <b-card-header>
          {{availableItemsHeading}}
        </b-card-header>
        <b-card-body class="border-bottom p-3">
          <filter-list-component v-on:filter="filterAvailableItems" />
        </b-card-body>
        <draggable-list-component :list="availableItemsFiltered" :group="group" :itemName="itemName" />
      </b-card>
    </div>
    <div class="col-1 d-flex align-items-center justify-content-center">
      <font-awesome-icon :icon="['fas', 'exchange']" />
    </div>
    <div class="col d-flex flex-column">
      <b-card no-body>
        <b-card-header class="d-flex align-items-center">
          <b-badge class="mr-3" pill variant="secondary">{{selectedItemsFiltered.length}}</b-badge>{{selectedItemsHeading}}
        </b-card-header>
        <b-card-body class="border-bottom p-3">
          <filter-list-component v-on:filter="filterSelectedItems" />
        </b-card-body>
        <draggable-list-component :list="selectedItemsFiltered" :group="group" :itemName="itemName" />
      </b-card>
    </div>
  </div>
</template>

<script>
import FilterListComponent from './FilterListComponent.vue'
import DraggableListComponent from './DraggableListComponent.vue'
import _ from 'lodash'

export default {
  name: 'DraggableComponent',
  components: {
    FilterListComponent,
    DraggableListComponent
  },
  props: {
    group: {
      type: String,
      default: 'group'
    },
    itemName: {
      type: String,
      default: 'name'
    },
    availableItems: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedItems: {
      type: Array,
      default: () => {
        return []
      }
    },
    availableItemsHeading: {
      type: String,
      default: 'Available Items'
    },
    selectedItemsHeading: {
      type: String,
      default: 'Selected Items'
    },
    maxOne: {
      type: Boolean,
      default: () => {
        return false
      }
    },
  },
  watch: {
    selectedItemsFiltered(selectedItemsFiltered) {
      if (selectedItemsFiltered.length > 1 && this.maxOne) {
        this.selectedItemsFiltered.pop()
      }
    }
  },
  methods: {
    filterAvailableItems(value) {
      this.availableItemsFiltered = _.filter(this.availableItems, element => _.includes(element.data.name.toLowerCase(), value.toLowerCase()) ? true : false)
    },
    filterSelectedItems(value) {
      this.selectedItemsFiltered = _.filter(this.selectedItems, element => _.includes(element.data.name.toLowerCase(), value.toLowerCase()) ? true : false)
    }
  },
  data() {
    return {
      availableItemsFiltered: this.availableItems,
      selectedItemsFiltered: this.selectedItems
    }
  }
}
</script>
