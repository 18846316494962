<template>
  <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner></b-spinner></div>
  <div class="container-fluid p-4" v-else>
    <div class="pb-4 d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <small class="text-muted mb-1 ">
          <font-awesome-icon icon="cog" fixed-width class="mr-1" />{{$t("global.ansichtBearbeiten")}}: {{$t("bukundenherstellerumsaetze.titel")}}
        </small>
        <h1 class="h2">{{$t("bukundenherstellerumsaetze.bukundenherstellerumsaetze")}}</h1>
      </div>
      <div class="d-flex">
        <router-link to="/bukundenherstellerumsaetze/erstellen" class="btn btn-primary align-self-center"><font-awesome-icon icon="plus" class="mr-2" />{{$t("bukundenherstellerumsaetze.erstellen")}}</router-link>
      </div>
    </div>
    <b-card no-body class="shadow-sm">
      <table class="table table-hover mb-0" v-if="bukundenherstellerumsaetze.length > 0">
        <tbody>
          <tr v-for="element in orderByIndex(bukundenherstellerumsaetze)" :key="`business-unit-${element.id}`">
            <td class="d-flex justify-content-between align-items-center">
              {{element.data.name}}
              <div>
                <router-link class="btn btn-secondary btn-sm mr-2" :to="`/bukundenherstellerumsaetze/bearbeiten/${element.id}`"><font-awesome-icon icon="edit" /></router-link>
                <button type="button" class="btn btn-danger btn-sm" v-on:click="loeschen(element.id, element.data.name)"><font-awesome-icon icon="trash" /></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-warning text-center" v-else>
        {{$t("global.keineDatenVorhanden")}}
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'

import { firestore } from "../../../firebase"
import {query, doc, orderBy, deleteDoc, getDocs, collection } from "firebase/firestore"

export default {
  name: 'ZeigeBuKundenHerstellerUmsaetze',
  mounted() {
    this.laden()
  },
  methods: {
    laden: async function() {
      const querySnapshot = await getDocs(query(collection(firestore, "bukundenherstellerumsatz"), orderBy("slug", "asc")))

      querySnapshot.forEach(doc => {
        const element = {
          id: doc.id,
          data: doc.data()
        }
        this.bukundenherstellerumsaetze.push(element)
      })

      this.isLoading = false
      
    },
    loeschen: function(id, name) {
      this.$bvModal.msgBoxConfirm(`${this.$t("global.wirklichLoeschen[0]")} "${name}"  ${this.$t("global.wirklichLoeschen[1]")}`, {
        title: '',
        okVariant: 'danger',
        okTitle: this.$t("global.ok"),
        cancelTitle: this.$t("global.abbrechen"),
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      })
      .then(value => {
        if (value === true) {
          deleteDoc(doc(firestore, "bukundenherstellerumsatz", id))
          .then(() => {
            for(let i = 0; i < this.bukundenherstellerumsaetze.length; i++){ 
              if (this.bukundenherstellerumsaetze[i].id === id) {
                this.bukundenherstellerumsaetze.splice(i, 1)
                i--
              }
            }
          })
          .catch(error => console.error(error)) // eslint-disable-line no-console
        }
      })
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    updateEntries: function(data) {
      this.kundenbetreuer = data
    },
    orderByIndex: function(data) {
      return _.orderBy(data, (entry) => entry.data.orderIndex, 'asc')
    }
  },
  data() {
    return {
      isLoading: true,
      bukundenherstellerumsaetze: []
    }
  }
}
</script>
