<template>
  <div v-if="umsaetze.length > 0">
    <b-card-body class="border-bottom">
      <filter-list-component @filter="filterList"></filter-list-component>
    </b-card-body>
    <b-table
      class="mb-0"
      striped
      :items="umsaetze"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc="true"
      :filter="filter"
      responsive
      hover
    >
      <template #cell(title)="data">
        <div @click="$emit('tablerowClick', data.item)">{{ data.item.title }}</div>
      </template>
      <template #cell(umsatzIst)="data">
        <div :class="umsatzIstGroeßerAlsSoll(data.item.umsatzIst, data.item.umsatzSollHeute)" @click="$emit('tablerowClick', data.item)">
          {{ data.item.umsatzIst | formatCurrency }}
        </div>
      </template>
      <template #cell(umsatzSollHeute)="data">
        <div @click="$emit('tablerowClick', data.item)">{{ data.item.umsatzSollHeute | formatCurrency }}</div>
      </template>
      <template #cell(umsatzzielMonat)="data">
        <div @click="$emit('tablerowClick', data.item)">{{ data.item.umsatzzielMonat | formatCurrency }}</div>
      </template>
      <template #cell(zielerreichungUmsatzzielMonat)="data">
        <div @click="$emit('tablerowClick', data.item)">{{ data.item.zielerreichungUmsatzzielMonat | formatPercent }}</div>
      </template>
      <template #custom-foot>
        <b-tr class="border-top" style="border-width: 2px !important">
          <b-th></b-th>
          <b-th :class="umsatzIstGroeßerAlsSoll(summiereUmsaetze(umsaetze), summiereUmsatzzielMonat(umsaetze))">
            {{ summiereUmsaetze(umsaetze) | formatCurrency }}
          </b-th>
          <b-th>
            {{ summiereUmsatzSollHeute(umsaetze) | formatCurrency }}
          </b-th>
           <b-th>
            {{ summiereUmsatzzielMonat(umsaetze) | formatCurrency }}
          </b-th>
          <b-th>
            {{ zielerreichungUmsatzzielMonatGesamt(umsaetze) | formatPercent }}
          </b-th>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>
<script>
import FilterListComponent from "../FilterListComponent.vue";
import { firestore, functions } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";

export default {
  name: "HerstellerumsaetzeTabelle",
  components: {
    FilterListComponent,
  },
  props: {
    kundenbetreuer: Object,
    selectedDate: String,
  },
  watch: {
    selectedDate(value) {
      this.selectedMonth = moment(value).format("YYYY-MM"); 
      this.fetchData();
    }
  },
  methods: {
    summiereUmsaetze(umsaetze) {
      return umsaetze.reduce((acc, item) => acc + item.umsatzIst, 0);
    },
    summiereUmsatzSollHeute(umsaetze) {
      return umsaetze.reduce((acc, item) => acc + item.umsatzSollHeute, 0);
    },
    summiereUmsatzzielMonat(umsaetze) {
      return umsaetze.reduce((acc, item) => acc + item.umsatzzielMonat, 0);
    },
    zielerreichungUmsatzzielMonatGesamt(umsaetze) {
      const umsatzzielMonat = umsaetze.reduce((acc, item) => acc + item.umsatzzielMonat, 0);
      const umsatzIst = umsaetze.reduce((acc, item) => acc + item.umsatzIst, 0);
      return umsatzIst / umsatzzielMonat * 100;
    },
    tageImMonat() {
      const anzahlTageDesMonats = moment(this.selectedDate).tz('Europe/Berlin').daysInMonth()
      let tageDesMonats = []
      let gesamtWerktageDesMonats = 0

      for (let tag = 1; tag <= anzahlTageDesMonats; tag++) {
        const datum = moment(this.selectedDate).tz('Europe/Berlin').date(tag).format('YYYY-MM-DD')
        const monat = moment(this.selectedDate).tz('Europe/Berlin').format('MM')

        let tagObject = {
          "datum": datum,
          "wochentag": moment(this.selectedDate).tz('Europe/Berlin').date(tag).format('dddd'),
          "feiertag": moment(datum).tz('Europe/Berlin').isHoliday('SH'),
          "werktagDesMonats": 0,
          "gesamtWerktageDesMonats": 0
        }

        if (
          monat === '12' && 
          (tag === 24 || tag === 31)
        ) {
          tagObject.feiertag = 'Interner Feiertag'
        }

        tageDesMonats.push(tagObject)
      }

      let werktagDesMonats = 1

      tageDesMonats.forEach(tagObject => {
        
        if (
          tagObject.wochentag !== 'Saturday' &&
          tagObject.wochentag !== 'Sunday' &&
          !tagObject.feiertag
        ) {
          tagObject.werktagDesMonats = werktagDesMonats++
          gesamtWerktageDesMonats++
        } else {
          tagObject.werktagDesMonats = werktagDesMonats - 1
        } 
      })

      tageDesMonats.forEach(tagObject => {
        tagObject.gesamtWerktageDesMonats = gesamtWerktageDesMonats
      })

      return tageDesMonats
    },
    umsatzIstGroeßerAlsSoll(umsatzIst, umsatzziel) {
      if (umsatzziel !== undefined) {
        return umsatzIst >=  umsatzziel? " text-success" : " text-danger";
      }
    },
    umsatzSollHeute(umsatzziele) {
      const tageImMonat = this.tageImMonat();
      const werktagDesMonats = tageImMonat.filter((tag) => tag.datum === moment(this.selectedDate).format("YYYY-MM-DD"))[0].werktagDesMonats;
      const monat = moment(this.selectedDate).format("MM");
      const tagesziel = umsatzziele[monat].tag;
      return tagesziel * werktagDesMonats;
    },
    umsatzzielMonat(umsatzziele) {
      const monat = moment(this.selectedDate).format("MM");
      return umsatzziele[monat].monat
    },
    zielerreichungUmsatzzielMonat(umsatzIst, umsatzziele) {
      const monat = moment(this.selectedDate).format("MM");

      if (umsatzziele[monat].monat === 0) {
        return 0;
      } else {
        return umsatzIst / umsatzziele[monat].monat * 100;
      }
    },
    filterList(value) {
      this.filter = value;
    },
    async getLieferantennummernLaendercodes() {
      const firestoreIds = this.kundenbetreuer.businessUnits.map((item) => item.id);
      const businessUnitDocs = await Promise.allSettled(firestoreIds.map((id) => getDoc(doc(firestore, "businessunits", id))));

      const herstellerFirestoreIds = [];
      const laenderFirestoreIds = [];

      businessUnitDocs.forEach((result) => {
        if (result.value.exists() && result.status === "fulfilled") {
          const docData = result.value.data();

          herstellerFirestoreIds.push(...docData.hersteller.map((hersteller) => hersteller.id));
          laenderFirestoreIds.push(...docData.laender.map((land) => land.id));
        }
      });

      const herstellerDocs = await Promise.allSettled(herstellerFirestoreIds.map((id) => getDoc(doc(firestore, "hersteller", id))));
      const lieferantennummern = herstellerDocs.filter((result) => result.value.exists() && result.status === "fulfilled").map((result) => result.value.data().lieferantennummern);

      const laenderDocs = await Promise.allSettled(laenderFirestoreIds.map((id) => getDoc(doc(firestore, "laender", id))));
      const laendercodes = laenderDocs.filter((result) => result.value.exists() && result.status === "fulfilled").map((result) => result.value.data().laendercode);

      return {
        lieferantennummern: lieferantennummern.flat(),
        laendercodes: laendercodes,
      };
    },
    async getKunden() {
      const firestoreIds = this.kundenbetreuer.kunden.map((item) => item.id);
      const promises = firestoreIds.map((id) => getDoc(doc(firestore, "kunden", id)));

      const results = await Promise.allSettled(promises);
      const dataset = [];

      results.forEach((result) => {
        if (result.value.exists() && result.status === "fulfilled") {
          const docData = result.value.data();

          if (docData.kundennummer) {
            docData.kundennummern = [docData.kundennummer];
          }

          dataset.push(docData);
        }
      });

      return dataset;
    },
    createQuery(lieferantennummernLaendercodes, kunden) {
      const laendercodesString = "'" + lieferantennummernLaendercodes.laendercodes.join("','") + "'";
      const lieferantennummernString = "'" + lieferantennummernLaendercodes.lieferantennummern.join("','") + "'";

      const queryArray = kunden.map((kunde) => {
        const kundennummernString = "'" + kunde.kundennummern.join("','") + "'";

        return `
          SELECT '${kunde.name}' AS title, SUM(\`Wert\`) As umsatz
          FROM \`clgmbh-trademaster.trademasterData.table*\`
          WHERE \`Datum\` BETWEEN '${this.selectedMonth}-01' AND '${this.selectedDate}'
          AND \`Lieferantennummer\` IN (${lieferantennummernString})
          AND \`Land\` IN (${laendercodesString})
          AND \`Kundennummer\` IN (${kundennummernString})
          AND \`Warengruppe\` NOT IN ('Technik RMA', 'CLEK_GSAnforderung', 'SONDERARTIKEL')
          AND \`Artikelnummer\` != 'B-1000-Acronis'
        `;
      });

      return queryArray.join(" UNION ALL ");
    },
    async fetchData() {
      const [lieferantennummernLaendercodes, kunden] = await Promise.all([
        this.getLieferantennummernLaendercodes(),
        this.getKunden(),
      ]);

      if (kunden.length > 0) {
        const query = this.createQuery(lieferantennummernLaendercodes, kunden);
        const queryBigQuery = httpsCallable(functions, "queryBigQuery");
        const result = await queryBigQuery({ query });

        const umsaetze = JSON.parse(result.data.data);

        let final = [];
        if (umsaetze.length > 0) {
          final = umsaetze.map((umsatz) => {
            const passenderKunde = kunden.find((kunde) => kunde.name === umsatz.title);
            umsatz.umsatzziele = passenderKunde ? passenderKunde.umsatzziele : null;
            umsatz.laendercodes = lieferantennummernLaendercodes.laendercodes ? lieferantennummernLaendercodes.laendercodes : null;
            umsatz.kundennummern = passenderKunde.kundennummern ? passenderKunde.kundennummern : null;
            umsatz.lieferantennummern = lieferantennummernLaendercodes.lieferantennummern ? lieferantennummernLaendercodes.lieferantennummern : null;
            umsatz.umsatz = umsatz.umsatz === null ? 0 : Number(umsatz.umsatz);
            return umsatz;
          });

          this.umsaetze = final.map(element => {
            return {
              title: element.title,
              umsatzIst: element.umsatz,
              umsatzSollHeute: this.umsatzSollHeute(element.umsatzziele),
              umsatzzielMonat: this.umsatzzielMonat(element.umsatzziele),
              zielerreichungUmsatzzielMonat: this.zielerreichungUmsatzzielMonat(element.umsatz, element.umsatzziele),
              extraData: {
                laendercodes: element.laendercodes,
                kundennummern: element.kundennummern,
                lieferantennummern: element.lieferantennummern
              }
            }
          })
        }
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      filter: null,
      umsaetze: [],
      sortBy: "umsatzIst",
      selectedMonth: moment().format("YYYY-MM"),
      fields: [
        {
          key: "title",
          label: this.$t("umsatzauswertungTabelle.kunde"),
          sortable: true,
        },
        {
          key: "umsatzIst",
          label: this.$t("umsatzauswertungTabelle.umsatzIst"),
          sortable: true,
        },
        {
          key: "umsatzSollHeute",
          label: this.$t("umsatzauswertungTabelle.umsatzSoll"),
          sortable: true,
        },
        {
          key: "umsatzzielMonat",
          label: this.$t("umsatzauswertungTabelle.umsatzZielMonat"),
          sortable: true,
        },
        {
          key: "zielerreichungUmsatzzielMonat",
          label: this.$t("umsatzauswertungTabelle.zielerreichung"),
          sortable: true,
        },
      ],
    };
  },
};
</script>
<style scoped>
th {
  white-space: nowrap;
}
th.sortierbar {
  cursor: pointer;
}
</style>