<template>
  <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner></b-spinner></div>

  <div class="container-fluid p-4" v-else>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
          <small class="text-muted mb-1 ">
            <font-awesome-icon icon="cog" fixed-width class="mr-1" />{{$t("global.ansichtBearbeiten")}}: {{$t("bukundenherstellerumsaetze.titel")}}
          </small>
          <h1 class="h2">{{$t("bukundenherstellerumsaetze.bearbeiten")}}</h1>
        </div>

        <form v-on:submit.prevent="validieren">
          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t('bukundenherstellerumsaetze.name')}}</label>
              <b-input v-model="name" placeholder="Kunde" required></b-input>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">BU zuordnen</label>
              <b-form-radio v-model="selectedBu" name="some-radios" value="Audio">Audio</b-form-radio>
              <b-form-radio v-model="selectedBu" name="some-radios" value="Video">Video</b-form-radio>
              <b-form-radio v-model="selectedBu" name="some-radios" value="Smart Basics">Smart Basics</b-form-radio>
              <b-form-radio v-model="selectedBu" name="some-radios" value="Frankreich">Frankreich</b-form-radio>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t("bukundenherstellerumsaetze.kunden")}}</label>
              <p class="text-muted mb-4"><font-awesome-icon :icon="['fas','exclamation-triangle']" fixed-width class="mr-2 text-danger" /><span v-html="$t('bukundenherstellerumsaetze.nurKundenWerdenBeruecksichtigt')"></span></p>
              <hr>
              <draggable-component 
                :group="'kunden'"
                :itemName="'name'"
                :maxOne="true"
                :availableItems="verfuegbareKunden"
                :selectedItems="ausgewaehlteKunden"
                :availableItemsHeading="$t('bukundenherstellerumsaetze.verfuegbareKunden')"
                :selectedItemsHeading="$t('bukundenherstellerumsaetze.ausgewaehlteKunden')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label mb-3">{{$t("bukundenherstellerumsaetze.hersteller")}}</label>
              <draggable-component 
                :group="'hersteller'"
                :itemName="'name'"
                :availableItems="verfuegbareHersteller"
                :selectedItems="ausgewaehlteHersteller"
                :availableItemsHeading="$t('bukundenherstellerumsaetze.verfuegbareHersteller')"
                :selectedItemsHeading="$t('bukundenherstellerumsaetze.ausgewaehlteHersteller')">
              </draggable-component>
            </b-card>
          </b-form-group>
          
          <b-button type="submit" variant="primary" block>{{$t("global.speichern")}}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import 'moment-feiertage'
import DraggableComponent from '../../DraggableComponent.vue'

import { firestore } from "../../../firebase"
import { getDoc, doc, query, orderBy, setDoc, getDocs, collection } from "firebase/firestore"

export default {
  name: 'BearbeiteBuKundenHerstellerUmsaetze',
  components: { DraggableComponent },
  mounted() {
    this.laden()
  },
  methods: {
    laden: function() {
      const firestorePromises = [
        getDoc(doc(firestore, "bukundenherstellerumsatz", this.$route.params.id)),
        getDocs(query(collection(firestore, "kunden"), orderBy("slug", "asc"))),
        getDocs(query(collection(firestore, "hersteller"), orderBy("slug", "asc"))),
      ]

      Promise.all(firestorePromises)
      .then(response => {
        const bukundenherstellerumsaetzeSnapshot = response[0]
        if (bukundenherstellerumsaetzeSnapshot.exists) {
          this.name = bukundenherstellerumsaetzeSnapshot.data().name
          this.ausgewaehlteHersteller = bukundenherstellerumsaetzeSnapshot.data().hersteller
          this.ausgewaehlteKunden = bukundenherstellerumsaetzeSnapshot.data().kunden
          this.selectedBu = bukundenherstellerumsaetzeSnapshot.data().selectedBu
        } else {
          this.$bvModal.msgBoxOk(this.$t("global.datensatzExistiertNicht"), {
            title: '',
            okVariant: 'primary',
            okTitle: this.$t("global.ok"),
            hideHeaderClose: false,
            noCloseOnBackdrop: true
          })
          .then(() => this.$router.replace('/bukundenherstellerumsaetze/bearbeiten'))
          .catch(error => console.error(error)) // eslint-disable-line no-console
        }

        const kundenSnapshot = response[1]
        kundenSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name,
              kundennummern: doc.data()?.kundennummern ? doc.data()?.kundennummern : [doc.data()?.kundennummer]
            }
          }
          this.verfuegbareKunden.push(element)
        })

        const herstellerSnapshot = response[2]
        herstellerSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name
            }
          }
          this.verfuegbareHersteller.push(element)
        })

        this.verfuegbareKunden = this.filterNotSelectedItems(this.verfuegbareKunden, this.ausgewaehlteKunden)
        this.verfuegbareHersteller = this.filterNotSelectedItems(this.verfuegbareHersteller, this.ausgewaehlteHersteller)
      })
      .then(() => this.isLoading = false)
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    filterNotSelectedItems(availableItems, selectedItems) {
      return availableItems.filter(availableItem => {
        const selectedItemIds = selectedItems.map(selectedItem => selectedItem.id)
        if (selectedItemIds.includes(availableItem.id)) {
          return false
        }
        return true
      })
    },
    validieren: function() {
      if (this.name === '') {
        this.$bvModal.msgBoxOk(this.$t("global.alleFelderAusfuellen"), {
          title: '',
          okVariant: 'primary',
          okTitle: this.$t("global.ok"),
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        })
        .catch(error => console.error(error)) // eslint-disable-line no-console
        return
      }
      this.speichern()
    },
    speichern: function() {

      setDoc( doc(firestore, "bukundenherstellerumsatz", this.$route.params.id), { 
        name: this.name,
        slug: this.name.toLowerCase(),
        kunden: this.ausgewaehlteKunden,
        hersteller: this.ausgewaehlteHersteller,
        selectedBu: this.selectedBu
      })
      .then(() => this.$router.replace('/bukundenherstellerumsaetze/bearbeiten'))
      .catch(error => console.error(error)) // eslint-disable-line no-console
    }
  },
  data() {
    return {
      isLoading: true,
      name: '',
      verfuegbareHersteller: [],
      ausgewaehlteHersteller: [],
      verfuegbareKunden: [],
      ausgewaehlteKunden: [],
      selectedBu: "Audio"
    }
  }
}
</script>
