// Vue App Components
import Vue from 'vue'
import App from './App.vue'

// Firebase 
import { auth } from './firebase.js'
import { onAuthStateChanged } from 'firebase/auth'

// Vue Router
import router from './router.js'

// Localization
import i18n from './i18n/i18n.js'

// Font Awesome
import './fontawesome.js'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";
Vue.use(BootstrapVue)

// Flag-icons
import "flag-icons/css/flag-icons.css";

// Moment
import moment from 'moment'
import 'moment-feiertage'

// Filters
import { formatCurrency, formatPercent, formatDate } from './filters.js'

// T4AData
import store from './store'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component("apexchart-vue", VueApexCharts)

// Hide Vue ProductionTip in Console
Vue.config.productionTip = false

function tage() {
  const anzahlTageDesMonats = moment().daysInMonth()
  let tageDesMonats = []
  let gesamtWerktageDesMonats = 0

  for (let tag = 1; tag <= anzahlTageDesMonats; tag++) {
    const datum = moment().date(tag).format('YYYY-MM-DD')
    const monat = moment().format('MM')

    let tagObject = {
      "datum": datum,
      "wochentag": moment().date(tag).format('dddd'),
      "feiertag": moment(datum).isHoliday('SH'),
      "werktagDesMonats": 0,
      "gesamtWerktageDesMonats": 0
    }

    if (
      monat === '12' && 
      (tag === 24 || tag === 31)
    ) {
      tagObject.feiertag = 'Interner Feiertag'
    }

    tageDesMonats.push(tagObject)
  }

  let werktagDesMonats = 1

  tageDesMonats.forEach(tagObject => {
    
    if (
      tagObject.wochentag !== 'Saturday' &&
      tagObject.wochentag !== 'Sunday' &&
      tagObject.feiertag === false
    ) {
      tagObject.werktagDesMonats = werktagDesMonats++
      gesamtWerktageDesMonats++
    } else {
      tagObject.werktagDesMonats = werktagDesMonats - 1
    } 
  })

  tageDesMonats.forEach(tagObject => {
    tagObject.gesamtWerktageDesMonats = gesamtWerktageDesMonats
  })

  return tageDesMonats
}

function gestern() {
  const gestern = moment().subtract(1, 'days').format('YYYY-MM-DD')
  const tageDesMonats = tage()
  let result = {
    abgelaufeneTage: 0,
    gesamtTage: 0
  }
  
  tageDesMonats.forEach(tag => {
    if (tag.datum === gestern) {
      result.abgelaufeneTage = tag.werktagDesMonats
      result.gesamtTage = tag.gesamtWerktageDesMonats
    }
  })

  return result
}

function heute() {
  const gestern = moment().format('YYYY-MM-DD')
  const tageDesMonats = tage()

  let result = {
    abgelaufeneTage: 0,
    gesamtTage: 0
  }
  
  tageDesMonats.forEach(tag => {
    if (tag.datum === gestern) {
      result.abgelaufeneTage = tag.werktagDesMonats
      result.gesamtTage = tag.gesamtWerktageDesMonats
    }
  })

  return result
}

// App Init
let app = ''
onAuthStateChanged(auth, () => {
  if (!app) {
    Vue.filter('formatCurrency', formatCurrency)
    Vue.filter('formatPercent', formatPercent)
    Vue.filter('formatDate', formatDate)
    app = new Vue({
      render: h => h(App),
      router,
      i18n,
      store,
      beforeMount() {
        Vue.prototype.$tage = tage()
        Vue.prototype.$gestern = gestern()
        Vue.prototype.$heute = heute() 
      }
    }).$mount('#app')
  }
})