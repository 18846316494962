<template>
  <div class="d-flex w-100 h-100">
    <Navbar />
    <div id="content" class="flex-grow-1 bg-light">
      <div class="container-fluid p-4">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 col-xl-8">
            <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
              <small class="text-muted mb-1">
                <font-awesome-icon icon="cogs" fixed-width class="mr-1" />{{$t("global.generelleEinstellungen")}}
              </small>
              <h1 class="h2">{{$t("generelleEinstellungenAjaUpload.titel")}}</h1>
            </div>
            <div class="container text-center">
              <b-form-file
                v-if="!finnished && !running"
                v-model="file"
                :state="Boolean(file)"
                placeholder="Eine Datei auswählen oder ablegen..."
                drop-placeholder="Datei hier reinziehen..."
                accept=".xlsx, .xls"
              ></b-form-file>
              <b-button @click="upload()" class="mt-2" v-if="file && !running">Hochladen</b-button>
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" v-if="running && !finnished"></b-spinner>
              <div class="d-flex justify-content-center">
                <div class="text-center" style="width: 250px;" v-if="finnished">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style="width: 40px;">
                    <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                    <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                  </svg><br>
                  Upload abgeschlossen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Moment
import moment from 'moment'

// import {getJsDateFromExcel} from 'excel-date-to-js'

import * as XLSX from 'xlsx'

import Navbar from '../Navbar.vue'

import { db } from "../../firebase"
import { set, ref } from "firebase/database"

export default {
  name: 'ajaImport',
  components: {
    Navbar
  },
  data() {
    return {
      file: undefined,
      fileReader: new FileReader(),
      running: false,
      finnished: false,
      alertState: 'secondary',
      alertText: 'Lade Informationen ...'
    }
  },
  methods: {
    async upload(){
      this.running = true
      this.fileReader.onload = async (e) => { 
        let workbook = XLSX.read(e.target.result, {type:'binary'});
        let firstSheet = workbook.SheetNames[0]
        let jsonFromExcelfile = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet])
        let newArray = []
        for (let index = 0; index < jsonFromExcelfile.length; index++) {
          if(jsonFromExcelfile[index]['Promise Date']  !== 'Scheduling' && jsonFromExcelfile[index]['Promise Date'] !== 'Promise Date'){
            newArray.push({
              partNr: jsonFromExcelfile[index].Primary,
              arrivalDate: moment(jsonFromExcelfile[index]['Promise Date'].toString(), 'DD/MM/YYYY').format('DD.MM.YYYY'),
              qty: jsonFromExcelfile[index].Quantity,
            })
          } 
        }
        set(ref(db, 'uploads/onTheRoad'), [{"name": "AJA", "produkte":newArray}])
        this.finnished = true
      }
      this.fileReader.readAsBinaryString(this.file)
    }
  }
}
</script>
