<template>
  <div class="container-fluid p-4 position-relative">
    <div class="pb-4 d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <small class="text-muted mb-1">
          <font-awesome-icon icon="chart-line" fixed-width class="mr-1" />{{
            $t("global.umsatzauswertung")
          }}
        </small>
        <h1 class="h2">{{ $t("bukundenherstellerumsaetze.titel") }}</h1>
      </div>
      <router-link
        to="/bukundenherstellerumsaetze/bearbeiten"
        class="btn btn-primary align-self-center"
        v-show="isAdmin"
      >
        <font-awesome-icon icon="cog" class="mr-2" />{{
          $t("global.ansichtBearbeiten")
        }}
      </router-link>
    </div>
    <div class="d-flex align-items-start">
      <b-card no-body class="shadow-sm flex-grow-1">
        <div
            class="d-flex flex-column justify-content-center p-3 align-items-center border-bottom"
          >
          <b-form-datepicker
            v-model="selectedDate"
            class="w-25"
            min="2019-01-01"
            :max="today"
          ></b-form-datepicker>
        </div>

        <b-tabs pills card vertical>
          <b-tab
            no-body
            :title="parent.name"
            v-for="(parent, parentindex) in foo"
            :key="`parenttab-${parentindex}`"
          >
            <b-tabs pills card vertical>
              <div v-for="(element, index) in kunden"
                  :key="`tab-${index}`">
                <b-tab
                  no-body
                  v-if="element.data.selectedBu === parent.name"
                  :title="element.data.name"
                  
                >
                  <BuKundenHerstellerUmsaetzeTabelle
                    
                    :kunde="element.data"
                    :selectedDate="selectedDate"
                    @tablerowClick="tablerowClick"
                  />
                </b-tab>
              </div>
            </b-tabs>
          </b-tab>
        </b-tabs>
      </b-card>

      <BuKundenHerstellerUmsaetzeChartbox
        :selectedTableRow="selectedTableRow"
        v-if="Object.keys(selectedTableRow).length > 0"
      ></BuKundenHerstellerUmsaetzeChartbox>
    </div>
  </div>
</template>

<script>
import BuKundenHerstellerUmsaetzeTabelle from "./BuKundenHerstellerUmsaetzeTabelle.vue";
import _ from "lodash";
import BuKundenHerstellerUmsaetzeChartbox from "./BuKundenHerstellerUmsaetzeChartbox.vue";
import { firestore } from "../../firebase";
import { collection, getDocs, query } from "firebase/firestore";
import moment from "moment-timezone";

export default {
  name: "BuKundenHerstellerUmsaetzeReiter",
  components: {
    BuKundenHerstellerUmsaetzeChartbox,
    BuKundenHerstellerUmsaetzeTabelle,
  },
  async mounted() {
    const querySnapshot = await getDocs(
      query(collection(firestore, "bukundenherstellerumsatz"))
    );

    this.kunden = _.orderBy(
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      })),
      (item) => item.data.name
    );
  },
  data() {
    return {
      kunden: [],
      selectedDate: moment().format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      selectedTableRow: {},
      foo: [
        {
          name: "Audio",
        },
        {
          name: "Video",
        },
        {
          name: "Smart Basics",
        },
        {
          name: "Frankreich",
        },
      ],
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin;
    },
  },
  methods: {
    tablerowClick(data) {
      return this.selectedTableRow = data;
    },
  },
};
</script>
