<template>
  <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner></b-spinner></div>
  <div class="container-fluid p-4" v-else>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
          <small class="text-muted mb-1">
            <font-awesome-icon icon="cogs" fixed-width class="mr-1" />{{$t("global.generelleEinstellungen")}}
          </small>
          <h1 class="h2">{{$t("generelleEinstellungenHersteller.herstellerBearbeiten")}}</h1>
        </div>
        <form v-on:submit.prevent="validieren">
          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label mb-3">{{$t("herstellerkundenumsaetze.hersteller")}}</label>
              <draggable-component 
                :group="'hersteller'"
                :itemName="'name'"
                :availableItems="verfuegbareHersteller"
                :selectedItems="ausgewaehlteHersteller"
                :availableItemsHeading="$t('herstellerkundenumsaetze.verfuegbareHersteller')"
                :selectedItemsHeading="$t('herstellerkundenumsaetze.ausgewaehlteHersteller')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-button type="submit" variant="primary" block>{{$t("global.speichern")}}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from '../../../firebase'
import {getDoc, getDocs, query, collection, orderBy, setDoc, doc} from "firebase/firestore"
import DraggableComponent from '../../DraggableComponent.vue'

export default {
  name: 'BearbeiteHersteller',
  components: { DraggableComponent },
  mounted() {
    this.laden()
  },
  methods: {
    filterNotSelectedItems(availableItems, selectedItems) {
      return availableItems.filter(availableItem => {
        const selectedItemIds = selectedItems.map(selectedItem => selectedItem.id)
        if (selectedItemIds.includes(availableItem.id)) {
          return false
        }
        return true
      })
    },
    validieren: function() {
      if (
        this.ausgewaehlteHersteller.length < 1 
      ) {
        this.$bvModal.msgBoxOk(this.$t("global.alleFelderAusfuellen"), {
          title: '',
          okVariant: 'primary',
          okTitle: this.$t("global.ok"),
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        })
        .catch(error => console.error(error)) // eslint-disable-line no-console
        return
      }
      this.speichern()
    },
    speichern: function() {
      setDoc(doc(firestore, "lagerHersteller", 'selected'), { 
        data: this.ausgewaehlteHersteller
      })
      .then(() => this.$router.replace('/lagerwerte'))
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    laden: function() {
      const firestorePromises = [
        getDocs(query(collection(firestore, "hersteller"), orderBy("slug", "asc"))),
        getDoc(doc(firestore, "lagerHersteller", "selected"))
      ]

      Promise.all(firestorePromises)
      .then(response => {

        const herstellerSnapshot = response[0]
        herstellerSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name
            }
          }
          this.verfuegbareHersteller.push(element)
        })

        this.ausgewaehlteHersteller = response[1].data()?.data || []
      

        this.verfuegbareHersteller = this.filterNotSelectedItems(this.verfuegbareHersteller, this.ausgewaehlteHersteller)
      })
      .then(() => this.isLoading = false)
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
  },
  data() {
    return {
      isLoading: true,
      verfuegbareHersteller: [],
      ausgewaehlteHersteller: [],
    }
  }
}
</script>
