<template>
  <button type="button" class="btn btn-secondary align-self-center mr-2" v-on:click="exportData" :disabled="disabled">
    <font-awesome-icon :icon="['fad', 'file-export']" class="mr-2" />{{$t("generelleEinstellungenHersteller.herstellerExportieren")}}
  </button>
</template>

<script>
import Papa from 'papaparse'
import { firestore } from "../../../firebase"
import { getDoc, doc } from "firebase/firestore"

export default {
  name: 'HerstellerExportierenComponent',
  props: {
    disabled: Boolean,
    selected: Array
  },
  methods: {
    exportData: function() {
      let promises = []
      this.selected.forEach(id => {
        promises.push(getDoc(doc(firestore, "hersteller", id)))
      })
      Promise.all(promises)
      .then(snapshots => {
        let suppliers = []
        snapshots.forEach(doc => {
          if (doc.exists) {
            const data = {
              id: doc.id,
              hersteller: doc.data().name,
              januar: doc.data().umsatzziele['01'].monat,
              februar: doc.data().umsatzziele['02'].monat,
              maerz: doc.data().umsatzziele['03'].monat,
              april: doc.data().umsatzziele['04'].monat,
              mai: doc.data().umsatzziele['05'].monat,
              juni: doc.data().umsatzziele['06'].monat,
              juli: doc.data().umsatzziele['07'].monat,
              august: doc.data().umsatzziele['08'].monat,
              september: doc.data().umsatzziele['09'].monat,
              oktober: doc.data().umsatzziele['10'].monat,
              november: doc.data().umsatzziele['11'].monat,
              dezember: doc.data().umsatzziele['12'].monat
            }
            suppliers.push(data)
          }
        })
        this.saveToFile(suppliers)
      })
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    saveToFile: function(suppliers) {
      this.$emit('clearSelected')
      let fileContents = {
        fields: ['id', 'hersteller', 'januar', 'februar', 'maerz', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'dezember'],
        data: suppliers
      }

      const csvData = Papa.unparse(fileContents, {
        delimiter: ";",
        newline: "\n",
        header: true
      })

      const blob = new Blob([csvData], {type: 'text/csv'})

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute('download', 'trademaster-hersteller-export.csv')
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>