<template>
  <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner></b-spinner></div>

  <div class="container-fluid p-4" v-else>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
          <small class="text-muted mb-1 ">
            <font-awesome-icon icon="cog" fixed-width class="mr-1" />{{$t("global.ansichtBearbeiten")}}: {{$t("herstellerkundenumsaetze.titel")}}
          </small>
          <h1 class="h2">{{$t("herstellerkundenumsaetze.erstellen")}}</h1>
        </div>

        <form v-on:submit.prevent="validieren">
          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t('herstellerkundenumsaetze.name')}}</label>
              <b-input v-model="name" placeholder="Hersteller" required></b-input>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label mb-3">{{$t("herstellerkundenumsaetze.hersteller")}}</label>
              <draggable-component 
                :group="'hersteller'"
                :itemName="'name'"
                :maxOne="true"
                :availableItems="verfuegbareHersteller"
                :selectedItems="ausgewaehlteHersteller"
                :availableItemsHeading="$t('herstellerkundenumsaetze.verfuegbareHersteller')"
                :selectedItemsHeading="$t('herstellerkundenumsaetze.ausgewaehlteHersteller')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t("herstellerkundenumsaetze.kunden")}}</label>
              <p class="text-muted mb-4"><font-awesome-icon :icon="['fas','exclamation-triangle']" fixed-width class="mr-2 text-danger" /><span v-html="$t('herstellerkundenumsaetze.nurKundenWerdenBeruecksichtigt')"></span></p>
              <hr>
              <draggable-component 
                :group="'kunden'"
                :itemName="'name'"
                :availableItems="verfuegbareKunden"
                :selectedItems="ausgewaehlteKunden"
                :availableItemsHeading="$t('herstellerkundenumsaetze.verfuegbareKunden')"
                :selectedItemsHeading="$t('herstellerkundenumsaetze.ausgewaehlteKunden')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-button type="submit" variant="primary" block>{{$t("global.speichern")}}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DraggableComponent from '../../DraggableComponent.vue'

import { firestore } from "../../../firebase"
import { query, orderBy, addDoc, getDocs, collection } from "firebase/firestore"

export default {
  name: 'ErstelleBusinessUnit',
  components: { DraggableComponent },
  mounted() {
    this.laden()
  },
  methods: {
    laden: function() {
      const firestorePromises = [
        getDocs(query(collection(firestore, "kunden"), orderBy("slug", "asc"))),
        getDocs(query(collection(firestore, "hersteller"), orderBy("slug", "asc")))
      ]

      Promise.all(firestorePromises)
      .then(response => {
        const kundenSnapshot = response[0]
        kundenSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name,
              kundennummern: doc.data()?.kundennummern ? doc.data()?.kundennummern : [doc.data()?.kundennummer]
            }
          }
          this.verfuegbareKunden.push(element)
        })

        const herstellerSnapshot = response[1]
        herstellerSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name
            }
          }
          this.verfuegbareHersteller.push(element)
        })

      })
      .then(() => this.isLoading = false)
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    validieren: function() {
      if (
        this.name === ''
      ) {
        this.$bvModal.msgBoxOk(this.$t("global.alleFelderAusfuellen"), {
          title: '',
          okVariant: 'primary',
          okTitle: this.$t("global.ok"),
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        })
        .catch(error => console.error(error)) // eslint-disable-line no-console
        return
      }
      this.speichern()
    },
    speichern: function() {
      addDoc(collection(firestore, "herstellerkundenumsatz"),{ 
        name: this.name,
        slug: this.name.toLowerCase(),
        kunden: this.ausgewaehlteKunden,
        hersteller: this.ausgewaehlteHersteller,
      })
      .then(() => this.$router.replace('/herstellerkundenumsaetze/zeige'))
      .catch(error => alert.error(error)) // eslint-disable-line no-console
    },
  },
  data() {
    return {
      isLoading: true,
      name: '',
      verfuegbareHersteller: [],
      ausgewaehlteHersteller: [],
      verfuegbareKunden: [],
      ausgewaehlteKunden: []
    }
  }
}
</script>
