<template>
  <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner></b-spinner></div>
  <div class="container-fluid p-4" v-else>
    <div class="pb-4 d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <small class="text-muted mb-1">
          <font-awesome-icon icon="cogs" fixed-width class="mr-1" />{{$t("global.generelleEinstellungen")}}
        </small>
        <h1 class="h2">{{$t("generelleEinstellungenHersteller.titel")}}</h1>
      </div>
      <div>
        <HerstellerExportierenComponent :disabled="selected.length === 0" :selected="selected" v-on:clearSelected="clearSelected" />
        <router-link to="/einstellungen/hersteller/importieren" class="btn btn-secondary align-self-center mr-2">
          <font-awesome-icon :icon="['fad', 'file-import']" class="mr-2" />{{$t("generelleEinstellungenHersteller.herstellerImportieren")}}
        </router-link>
        <router-link to="/einstellungen/hersteller/erstellen" class="btn btn-primary align-self-center">
          <font-awesome-icon icon="plus" class="mr-2" />{{$t("generelleEinstellungenHersteller.herstellerErstellen")}}
        </router-link>
      </div>
    </div>
    
    <b-card no-body class="shadow-sm">
      <b-card-body class="border-bottom">
        <filter-list-component v-on:filter="filterList" />
      </b-card-body>
      <table class="table table-hover mb-0" v-if="hersteller.length > 0">
        <thead>
          <tr>
            <th>
              <b-form-checkbox v-model="selectAll" switch>Alle auswählen</b-form-checkbox>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="element in filteredHersteller" :key="`hersteller-${element.id}`">
            <td class="d-flex justify-content-between align-items-center">
              <div>
                <b-form-checkbox
                  v-model="selected"
                  :value="element.id"
                  switch
                >{{element.data.name}}</b-form-checkbox>
              </div>
              <div>
                <router-link class="btn btn-secondary btn-sm mr-2" :to="`/einstellungen/hersteller/bearbeiten/${element.id}`"><font-awesome-icon icon="edit" /></router-link>
                <button type="button" class="btn btn-danger btn-sm" v-on:click="loeschen(element.id, element.data.name)"><font-awesome-icon icon="trash" /></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-warning text-center" v-else>
        {{$t("global.keineDatenVorhanden")}}
      </div>
    </b-card>
  </div>
</template>

<script>
import HerstellerExportierenComponent from './HerstellerExportierenComponent'
import FilterListComponent from '../../FilterListComponent'
import _ from 'lodash'

import { firestore } from "../../../firebase"
import { query, deleteDoc, doc, collection, getDocs, orderBy } from "firebase/firestore"

export default {
  name: 'ZeigeHersteller',
  components: {
    HerstellerExportierenComponent,
    FilterListComponent
  },
  mounted() {
    this.laden()
  },
  watch: {
    selectAll(value) {
      if (value) {
        this.selected = this.filteredHersteller.map(element => element.id)
      } else {
        this.selected = []
      }
    },
    hersteller(hersteller) {
      this.filteredHersteller = hersteller
    }
  },
  methods: {
    filterList(value) {
      this.filteredHersteller = _.filter(this.hersteller, element => {
       if (_.includes(element.data.name.toLowerCase(), value.toLowerCase())) {
         return true
       }
       return false
      })
    },
    clearSelected: function() {
      this.selectAll = false
    },
    laden: async function() {
      const querySnapshot = await getDocs(query(collection(firestore, "hersteller"), orderBy("slug", "asc")))
      
        querySnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: doc.data()
          }
          this.hersteller.push(element)
          this.filteredHersteller.push(element)
        })

        this.isLoading = false
      
    },
    loeschen: function(id, name) {
      this.$bvModal.msgBoxConfirm(`${this.$t("global.wirklichLoeschen[0]")} "${name}"  ${this.$t("global.wirklichLoeschen[1]")}`, {
        title: '',
        okVariant: 'danger',
        okTitle: this.$t("global.ok"),
        cancelTitle: this.$t("global.abbrechen"),
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      })
      .then(value => {
        if (value === true) {
          deleteDoc(doc(firestore, "hersteller", id))
          .then(() => {
            for(let i = 0; i < this.hersteller.length; i++){ 
              if (this.hersteller[i].id === id) {
                this.hersteller.splice(i, 1)
                i--
              }
            }
          })
          .catch(error => console.error(error)) // eslint-disable-line no-console
        }
      })
      .catch(error => console.error(error)) // eslint-disable-line no-console
    }
  },
  data() {
    return {
      isLoading: true,
      hersteller: [],
      filteredHersteller: [],
      selected: [],
      selectAll: false
    }
  }
}
</script>
