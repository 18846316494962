<template>
  <div class="container-fluid p-4 position-relative">
    <div class="pb-4 d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <small class="text-muted mb-1">
          <font-awesome-icon icon="chart-line" fixed-width class="mr-1" />{{
            $t("global.umsatzauswertung")
          }}
        </small>
        <h1 class="h2">{{ $t("kundenumsaetze.titel") }}</h1>
      </div>
      <router-link
        to="/kundenumsaetze/bearbeiten"
        class="btn btn-primary align-self-center"
        v-show="isAdmin"
      >
        <font-awesome-icon icon="cog" class="mr-2" />{{
          $t("global.ansichtBearbeiten")
        }}
      </router-link>
    </div>
    <div class="d-flex align-items-start">
      <b-card no-body class="shadow-sm flex-grow-1">
        <div
          class="d-flex flex-column justify-content-center p-3 align-items-center border-bottom"
        >
          <b-form-datepicker
            v-model="selectedDate"
            class="w-25"
            min="2019-01-01"
            :max="today"
          ></b-form-datepicker>
        </div>
        <b-tabs pills card vertical>
          <b-tab
            no-body
            :title="`${element.data.vorname} ${element.data.nachname}`"
            v-for="(element, index) in kundenbetreuer"
            :key="`tab-${index}`"
          >
            <KundenumsaetzeTabelle
              :kundenbetreuer="element.data"
              :selectedDate="selectedDate"
              @tablerowClick="tablerowClick"
            />
          </b-tab>
        </b-tabs>
      </b-card>
      <KundenumsaetzeChartbox
        :selectedTableRow="selectedTableRow"
        v-if="Object.keys(selectedTableRow).length > 0"
      ></KundenumsaetzeChartbox>
    </div>
  </div>
</template>

<script>
import KundenumsaetzeTabelle from "./KundenumsaetzeTabelle.vue";
import _ from "lodash";
import KundenumsaetzeChartbox from "./KundenumsaetzeChartbox.vue";
import { firestore } from "../../firebase";
import { collection, getDocs, query } from "firebase/firestore";
import moment from "moment-timezone";

export default {
  name: "KundenumsaetzeReiter",
  components: {
    KundenumsaetzeTabelle,
    KundenumsaetzeChartbox,
  },
  async mounted() {
    const querySnapshot = await getDocs(
      query(collection(firestore, "kundenbetreuer"))
    );

    this.kundenbetreuer = _.orderBy(
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      })),
      (item) => item.data.orderIndex
    );
  },
  data() {
    return {
      kundenbetreuer: [],
      selectedDate: moment().format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      selectedTableRow: {},
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin;
    },
  },
  methods: {
    tablerowClick(data) {
      return this.selectedTableRow = data;
    },
  },
};
</script>
