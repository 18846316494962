<template>
  <div class="d-flex w-100 h-100">
    <Navbar />
    <div id="content" class="flex-grow-1 bg-light">
      <div class="container-fluid p-4 position-relative">
        <div class="bg-light">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../Navbar.vue'

export default {
  name: 'LagerwerteComponent',
  components: {
    Navbar
  },
}
</script>
