<template>
  <div>
    <table class="table m-0">
      <thead>
        <tr>
          <th colspan="7">{{ $t("uebersicht.auftraegeHeute.titel") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ $t("uebersicht.auftraegeHeute.auftraegeAnzahl") }}</td>
          <td class="text-right">{{ auftragAnzahl }}</td>
        </tr>
        <tr>
          <td>{{ $t("uebersicht.auftraegeHeute.auftragswert") }}</td>
          <td class="text-right">{{ auftragSumme | formatCurrency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DiverseComponent",
  props: {
    businessUnit: Object,
  },
  data() {
    return {
      auftragAnzahl: 0,
      auftragSumme: 0,
    };
  },
  mounted() {
    const auftragseingang = this.$store.state.auftragseingang.find(
      (eingang) => {
        if (eingang.name === this.businessUnit.name) {
          return eingang;
        }
      }
    );

    this.auftragAnzahl = auftragseingang.auftragAnzahl;
    this.auftragSumme = auftragseingang.auftragSumme;
  },
};
</script>
