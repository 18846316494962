<template>
  <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner></b-spinner></div>
  <div class="container-fluid p-4" v-else>
    <div class="pb-4 d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <small class="text-muted mb-1">
          <font-awesome-icon icon="cogs" fixed-width class="mr-1" />{{$t("global.generelleEinstellungen")}}
        </small>
        <h1 class="h2">{{$t("generelleEinstellungenLaender.titel")}}</h1>
      </div>
      <router-link to="/einstellungen/laender/erstellen" class="btn btn-primary align-self-center"><font-awesome-icon icon="plus" class="mr-2" />{{$t("generelleEinstellungenLaender.landErstellen")}}</router-link>
    </div>
    <b-card no-body class="shadow-sm">
      <b-card-body class="border-bottom">
        <filter-list-component v-on:filter="filterList" />
      </b-card-body>
      <table class="table table-hover mb-0" v-if="filteredLaender.length > 0">
        <tbody>
          <tr v-for="element in filteredLaender" :key="`hersteller-${element.id}`">
            <td class="d-flex justify-content-between align-items-center">
              {{element.data.land}}
              <div>
                <router-link class="btn btn-secondary btn-sm mr-2" :to="`/einstellungen/laender/bearbeiten/${element.id}`"><font-awesome-icon icon="edit" /></router-link>
                <button type="button" class="btn btn-danger btn-sm" v-on:click="loeschen(element.id, element.data.land)"><font-awesome-icon icon="trash" /></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-warning text-center" v-else>
        {{$t("global.keineDatenVorhanden")}}
      </div>
    </b-card>
  </div>
</template>

<script>
import FilterListComponent from '../../FilterListComponent'
import _ from 'lodash'
import { firestore } from '../../../firebase'
import { doc, deleteDoc, query, orderBy, collection, getDocs } from "firebase/firestore"

export default {
  name: 'ZeigeLaender',
  components: {
    FilterListComponent
  },
  mounted() {
    this.laden()
  },
  watch: {
    laender(laender) {
      this.filteredLaender = laender
    }
  },
  methods: {
    filterList(value) {
      this.filteredLaender = _.filter(this.laender, element => {
       if (_.includes(element.data.land.toLowerCase(), value.toLowerCase())) {
         return true
       }
       return false
      })
    },
    laden: async function() {
      const querySnapshot = await getDocs(query(collection(firestore, "laender"), orderBy("slug", "asc")))

      querySnapshot.forEach(doc => {
        const element = {
          id: doc.id,
          data: doc.data()
        }
        this.laender.push(element)
        this.filteredLaender.push(element)
      })

      this.isLoading = false

    },
    loeschen: function(id, land) {
      this.$bvModal.msgBoxConfirm(`${this.$t("global.wirklichLoeschen[0]")} "${land}"  ${this.$t("global.wirklichLoeschen[1]")}`, {
        title: '',
        okVariant: 'danger',
        okTitle: this.$t("global.ok"),
        cancelTitle: this.$t("global.abbrechen"),
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      })
      .then(value => {
        if (value === true) {
          deleteDoc(doc(firestore, "laender", id))
          .then(() => {
            for(let i = 0; i < this.laender.length; i++){ 
              if (this.laender[i].id === id) {
                this.laender.splice(i, 1)
                i--
              }
            }
          })
          .catch(error => console.error(error)) // eslint-disable-line no-console
        }
      })
      .catch(error => console.error(error)) // eslint-disable-line no-console
    }
  },
  data() {
    return {
      isLoading: true,
      laender: [],
      filteredLaender: []
    }
  }
}
</script>
