<template>
  <draggable :group="group" :sort="false" :list="list" class="draggable-col-container">
    <div v-for="element in list" :key="`element-${element.id}`" class="border-bottom draggable-item">
      <div class="card-body p-3">{{element.data[itemName]}}</div>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DraggableListComponent',
  components: {
    draggable
  },
  props: {
    group: {
      type: String,
      default: 'group'
    },
    itemName: {
      type: String,
      default: 'name'
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>
.draggable-col-container {
  height: 398px;
  overflow-y: scroll;
}
.draggable-item {
  cursor: move;
}
</style>