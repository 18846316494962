import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyC0v0j03oEsFkqa0Ftq42e-_U4qjxomIOI",
  authDomain: "clgmbh-trademaster.firebaseapp.com",
  databaseURL: "https://clgmbh-trademaster-eu.europe-west1.firebasedatabase.app",
  projectId: "clgmbh-trademaster",
  storageBucket: "clgmbh-trademaster.appspot.com",
  messagingSenderId: "336104609752",
  appId: "1:336104609752:web:cac71d33a7939a7e",
};

const region = "europe-west3";

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const db = getDatabase(firebase);
const firestore = getFirestore(firebase);
const functions = getFunctions(firebase, region);

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectDatabaseEmulator(db, "localhost", 9000);
  connectFirestoreEmulator(firestore, "localhost", 8082);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { firebase, auth, firestore, functions, db, region };
