<template>
  <b-input-group>
    <b-input-group-prepend>
      <b-button variant="danger" v-on:click="clear"><font-awesome-icon icon="times" /></b-button>
    </b-input-group-prepend>
    <b-input v-model="filter" :placeholder="$t('global.suche')"></b-input>
  </b-input-group>
</template>

<script>
export default {
  name: 'FilterListComponent',
  methods: {
    clear() {
      this.filter = ''
    }
  },
  watch: {
    filter(value) {
      this.$emit('filter', value)
    }
  },
  data() {
    return {
      filter: ''
    }
  }
}
</script>