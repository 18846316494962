<template>
  <div class="container-fluid p-4">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
          <small class="text-muted mb-1">
            <font-awesome-icon icon="cogs" fixed-width class="mr-1" />{{$t("global.generelleEinstellungen")}}
          </small>
          <h1 class="h2">{{$t("generelleEinstellungenLaender.landErstellen")}}</h1>
        </div>

        <form v-on:submit.prevent="validieren">
          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t('generelleEinstellungenLaender.land')}}</label>
              <b-input v-model="land" placeholder="Deutschland" required></b-input>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t('generelleEinstellungenLaender.laendercode')}}</label>
              <p class="text-muted">
                {{$t("generelleEinstellungenLaender.laendercodeHinweis[0]")}}
                <a :href="$t('generelleEinstellungenLaender.laendercodeLink')" target="_blank">{{$t("generelleEinstellungenLaender.laendercodeHinweis[1]")}}</a>
                {{$t("generelleEinstellungenLaender.laendercodeHinweis[2]")}}
              </p>
              <b-input v-model="laendercode" placeholder="DE" required></b-input>
            </b-card>
          </b-form-group>

          <b-button type="submit" variant="primary" block>{{$t("global.speichern")}}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import {firestore} from "../../../firebase"
import { getDoc, doc, setDoc } from "firebase/firestore"

export default {
  name: 'BearbeiteLand',
  mounted() {
    this.laden()
  },
  methods: {
    validieren: function() {
      if (
        this.land === '' || 
        this.laendercode === ''
      ) {
        this.$bvModal.msgBoxOk(this.$t("global.alleFelderAusfuellen"), {
          title: '',
          okVariant: 'primary',
          okTitle: this.$t("global.ok"),
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        })
        .catch(error => console.error(error)) // eslint-disable-line no-console
        return
      }
      this.speichern()
    },
    speichern: function() {
    setDoc(doc(firestore, "laender"), { 
        land: this.land,
        laendercode: this.laendercode.toUpperCase(),
        slug: this.land.toLowerCase(),
      })
      .then(() => this.$router.replace('/einstellungen/laender'))
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    laden: function() {
      getDoc(doc(firestore, "laender", this.$route.params.id))
      .then(doc => {
        if (doc.exists) {
          this.land = doc.data().land
          this.laendercode = doc.data().laendercode
        } else {
          this.$bvModal.msgBoxOk(this.$t("global.datensatzExistiertNicht"), {
            title: '',
            okVariant: 'primary',
            okTitle: this.$t("global.ok"),
            hideHeaderClose: false,
            noCloseOnBackdrop: true
          })
          .then(() => this.$router.replace('/einstellungen/laender'))
          .catch(error => console.error(error)) // eslint-disable-line no-console
        }
      })
      .then(() => {
        this.isLoading = false
      })
      .catch(error => console.error(error)) // eslint-disable-line no-console
    }
  },
  data() {
    return {
      land: '',
      laendercode: ''
    }
  }
}
</script>
