<template>
  <div v-if="businessUnit.produkte !== undefined">
    <b-card-body class="border-bottom">
      <filter-list-component v-on:filter="filterList"></filter-list-component>
    </b-card-body>
    <b-table striped :items="items" :fields="fields" :sort-by.sync="sortBy" :filter="filter">
      <template v-slot:cell(verfuegbar)="data">
        <font-awesome-icon icon="circle" :class="istVerfuegbar(data.item[6], data.item[9], data.item[7])" />
      </template>
    </b-table>
  </div>
  <div class="alert alert-warning text-center m-3" v-else><font-awesome-icon icon="info-circle" /> {{$t("global.keineDatenVorhanden")}}</div>
</template>

<script>
import FilterListComponent from '../FilterListComponent.vue'

export default {
  name: 'WareneingangTabelle',
  components: {
    FilterListComponent,
  },
  props: {
    businessUnit: Object,
  },
  computed: {
    items() {
      return this.businessUnit.produkte.map(produkt => {
        produkt.push(this.freieMenge(produkt[6], produkt[9], produkt[7]))
        return produkt
      })
    }
  },
  methods: {
    istVerfuegbar: function(lagermenge, gesamtWareneingangsmenge, reserviertemenge) {
      const menge = (parseInt(lagermenge) + parseInt(gesamtWareneingangsmenge)) - parseInt(reserviertemenge)
      return menge >= 1 ? 'text-success' : 'text-danger'
    },
    freieMenge: function(lagermenge, gesamtWareneingangsmenge, reserviertemenge) {
      const menge = (parseInt(lagermenge) + parseInt(gesamtWareneingangsmenge)) - parseInt(reserviertemenge)
      return menge <= 0 ?  0 : menge
    },
    filterList(value) {
      this.filter = value
    }
  },
  data() {
    return {
      filter: null,
      sortBy: '0',
      fields: [
        {
          key: 'verfuegbar',
          label: '',
          sortable: false,
        },
        {
          key: '0',
          label: this.$t("wareneingang.artikelnummer"),
          sortable: true,
        },
        {
          key: '1',
          label: this.$t("wareneingang.herstellernummer"),
          sortable: true,
        },
        {
          key: '2',
          label: this.$t("wareneingang.bezeichnung"),
          sortable: true,
        },
        {
          key: '9',
          label: this.$t("wareneingang.weMenge"),
          sortable: true,
        },
        {
          key: '6',
          label: this.$t("wareneingang.lager"),
          sortable: true,
        },
        {
          key: '7',
          label: this.$t("wareneingang.reserviert"),
          sortable: true,
        },
        {
          key: '10',
          label: this.$t("wareneingang.frei"),
          sortable: true,
        },
        {
          key: '8',
          label: this.$t("wareneingang.bestellt"),
          sortable: true,
        }
      ],
    }
  }
}
</script>

<style>
th {
  white-space: nowrap !important;
}
.card-costom-style .card-body{
  padding: 0 !important;
}
</style>
