<template>
  <div class="container-fluid p-4">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
          <small class="text-muted mb-1">
            <font-awesome-icon icon="cogs" fixed-width class="mr-1" />{{$t("global.generelleEinstellungen")}}
          </small>
          <h1 class="h2">{{$t("generelleEinstellungenKunden.kundeErstellen")}}</h1>
        </div>

        <form v-on:submit.prevent="validieren">
          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t('generelleEinstellungenKunden.name')}}</label>
              <b-input v-model="name" placeholder="Mustermann GmbH" required></b-input>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t('generelleEinstellungenKunden.kundennummer')}}</label>
              <b-input-group>
                <b-input v-model="neueKundennummer"></b-input>
                <b-input-group-append>
                  <b-button variant="secondary" v-on:click="validiereKundennummer"><font-awesome-icon icon="plus" /></b-button>
                </b-input-group-append>
              </b-input-group>
              <hr v-show="kundennummern.length > 0">
              <b-input-group v-for="(element, index) in kundennummern" :key="`kundennummer-${index}`" class="mt-2">
                <b-input v-model="kundennummern[index]"></b-input>
                <b-input-group-append>
                  <b-button variant="danger" v-on:click="loescheKundennummer(index)"><font-awesome-icon icon="trash" /></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t("generelleEinstellungenKunden.monatsumsatzziele")}}</label>
              <p class="text-muted">{{$t("generelleEinstellungenKunden.monatsumsatzzieleHinweis")}}</p>

              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2"
                :label="$t('global.monate[0]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielJanuar" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[1]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielFebruar" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[2]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielMaerz" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[3]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielApril" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[4]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielMai" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[5]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielJuni" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[6]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielJuli" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[7]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielAugust" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[8]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielSeptember" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[9]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielOktober" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[10]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielNovember" required></b-form-input>
              </b-form-group>
              <b-form-group 
                label-cols-sm="3" 
                label-cols-md="2" 
                :label="$t('global.monate[11]')">
                <b-form-input type="number" min="0" step="1" v-model.number="umsatzzielDezember" required></b-form-input>
              </b-form-group>
            </b-card>
          </b-form-group>

          <b-button type="submit" variant="primary" block>{{$t("global.speichern")}}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// Moment
import moment from 'moment'
import 'moment-feiertage'

import { firestore } from "../../../firebase"
import { collection, addDoc } from "firebase/firestore"

export default {
  name: 'ErstelleKunde',
  methods: {
    validieren: function() {
      if (
        this.name === '' || 
        this.kundennummern.length === 0 ||
        this.umsatzzielJanuar === '' ||
        this.umsatzzielFebruar === '' ||
        this.umsatzzielMaerz === '' ||
        this.umsatzzielApril === '' ||
        this.umsatzzielMai === '' ||
        this.umsatzzielJuni === '' ||
        this.umsatzzielJuli === '' ||
        this.umsatzzielAugust === '' ||
        this.umsatzzielSeptember === '' ||
        this.umsatzzielOktober === '' ||
        this.umsatzzielNovember === '' ||
        this.umsatzzielDezember === ''
      ) {
        this.$bvModal.msgBoxOk(this.$t("global.alleFelderAusfuellen"), {
          title: '',
          okVariant: 'primary',
          okTitle: this.$t("global.ok"),
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        })
        .catch(error => console.error(error)) // eslint-disable-line no-console
        return
      }
      this.speichern()
    },
    speichern: function() {
      const umsatzziele = {
        '01': { 'monat': this.umsatzzielJanuar, 'tag': this.berechneTagesziel(this.umsatzzielJanuar, '01') },
        '02': { 'monat': this.umsatzzielFebruar, 'tag': this.berechneTagesziel(this.umsatzzielFebruar, '02') },
        '03': { 'monat': this.umsatzzielMaerz, 'tag': this.berechneTagesziel(this.umsatzzielMaerz, '03') },
        '04': { 'monat': this.umsatzzielApril, 'tag': this.berechneTagesziel(this.umsatzzielApril, '04') },
        '05': { 'monat': this.umsatzzielMai, 'tag': this.berechneTagesziel(this.umsatzzielMai, '05') },
        '06': { 'monat': this.umsatzzielJuni, 'tag': this.berechneTagesziel(this.umsatzzielJuni, '06') },
        '07': { 'monat': this.umsatzzielJuli, 'tag': this.berechneTagesziel(this.umsatzzielJuli, '07') },
        '08': { 'monat': this.umsatzzielAugust, 'tag': this.berechneTagesziel(this.umsatzzielAugust, '08') },
        '09': { 'monat': this.umsatzzielSeptember, 'tag': this.berechneTagesziel(this.umsatzzielSeptember, '09') },
        '10': { 'monat': this.umsatzzielOktober, 'tag': this.berechneTagesziel(this.umsatzzielOktober, '10') },
        '11': { 'monat': this.umsatzzielNovember, 'tag': this.berechneTagesziel(this.umsatzzielNovember, '11') },
        '12': { 'monat': this.umsatzzielDezember, 'tag': this.berechneTagesziel(this.umsatzzielDezember, '12') }
      }
      addDoc(collection(firestore, "kunden"), { 
        name: this.name,
        slug: this.name.toLowerCase(),
        kundennummern: this.kundennummern,
        umsatzziele: umsatzziele
      })
      .then(() => this.$router.replace('/einstellungen/kunden'))
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    validiereKundennummer: function() {
      if (this.neueKundennummer === '') {
        this.$bvModal.msgBoxOk(this.$t("global.alleFelderAusfuellen"), {
          title: '',
          okVariant: 'primary',
          okTitle: this.$t("global.ok"),
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        })
        .catch(error => console.error(error)) // eslint-disable-line no-console
        return
      }
      this.erstelleKundennummer()
    },
    erstelleKundennummer: function() {
      this.kundennummern.push(this.neueKundennummer.trim())
      this.neueKundennummer = ''
    },
    loescheKundennummer: function(index) {
      this.kundennummern.splice(index, 1)
    },
    berechneTagesziel: function(monatsziel, monat) {
      if (monatsziel === 0) {
        return 0
      }

      const jahr = moment().year()
      const tageInMonat = moment(jahr + '-' + monat, 'YYYY-MM').daysInMonth()

      let werktageInMonat = 0

      for (let tag = 1; tag <= tageInMonat; tag++) {
        const wochentag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).format('dddd')
        let feiertag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).isHoliday('SH')

        if (
          monat === '12' && 
          (tag === 24 || tag === 31)
        ) {
          feiertag = 'Interner Feiertag'
        }

        if (
          wochentag !== 'Saturday' &&
          wochentag !== 'Sunday' &&
          feiertag === false
        ) {
          werktageInMonat++
        }
      }

      return +parseFloat(monatsziel / werktageInMonat).toFixed(2)
    }
  },
  data() {
    return {
      name: '',
      neueKundennummer: '',
      kundennummern: [],
      umsatzzielJanuar: 0,
      umsatzzielFebruar: 0,
      umsatzzielMaerz: 0,
      umsatzzielApril: 0,
      umsatzzielMai: 0,
      umsatzzielJuni: 0,
      umsatzzielJuli: 0,
      umsatzzielAugust: 0,
      umsatzzielSeptember: 0,
      umsatzzielOktober: 0,
      umsatzzielNovember: 0,
      umsatzzielDezember: 0
    }
  }
}
</script>
