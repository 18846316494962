const en_EN = {
  global: {
    reihenfolgeAendern: 'Change sorting',
    bitteWarten: 'Please wait',
    erledigt: 'Done',
    fehler: 'Error',
    umsatzauswertung: 'Sales evaluation',
    generelleEinstellungen: 'General settings',
    sonstiges: 'Miscellaneous',
    keineDatenVorhanden: 'There is no data available.',
    hinweis: 'This table shows all previous sales. (including today)',
    ansichtBearbeiten: 'Edit view',
    monate: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    speichern: 'Save',
    wirklichLoeschen: [
      'Should the record ',
      ' really be deleted?'
    ],
    alleFelderAusfuellen: 'Please fill in all fields to continue.',
    diesesFeldAusfuellen: 'Please fill in the field to continue.',
    datensatzExistiertNicht: 'This record does not exist.',
    ok: 'OK',
    abbrechen: 'Cancel',
    suche: 'Search'
  },
  anAbmelden: {
    titel: 'Log in',
    anmelden: 'Log in',
    abmelden: 'Log out',
    email: 'Your e-mail address',
    passwort: 'Your password',
    sprache: 'Language',
    ungeueltigeEmail: 'The email address is invalid.',
    keinPasswort: 'No password entered.',
    ungeueltigesPasswort: 'The password is invalid.',
    benutzerNichtGefunden: 'There is no user record corresponding to this identifier.',
    benutzerDeaktiviert: 'The user account has been disabled by an administrator.'
  },
  uebersicht: {
    titel: 'Overview',
    umsatzMonat: {
      titel: 'Sales month (up until yesterday)',
      umsatzIst: 'Volume',
      umsatzSoll: 'Goal (linear)',
      hrVonUmsatzIst: 'Forecast (projection)',
      zielFix: 'Forecast (fix)',
      zielerfuellungHr: 'Fulfillment (projection)',
      zielerfuellungIst: 'Fulfillment',
      zielerfuellungSoll: 'Fulfillment (goal)'
    },
    umsatzHeute: {
      titel: 'Sales today',
      umsatzIst: 'Volume',
      umsatzSoll: 'Daily goal (linear)',
      zielerfuellungTag: 'Fulfillment',
      tageszielHrMonat: 'Daily goal (projection)'
    },
    auftraegeHeute: {
      titel: 'Orders today',
      auftraegeAnzahl: 'Orders amount',
      auftragswert: 'Order value (sell-in)'
    }
  },
  herstellerumsaetze: {
    titel: 'Business Units > Manufacturers',
    businessUnits: 'Business Units',
    buErstellen: 'Create Business Unit',
    buBearbeiten: 'Edit Business Unit',
    name: 'Name',
    monatsumsatzziele: 'Monthly sales targets',
    monatsumsatzzieleHinweis: 'The daily sales targets are automatically calculated when saving.',
    monatsumsatzzieleGanzeZahlenHinweis: 'Enter only whole numbers!',
    hersteller: 'Manufacturers',
    verfuegbareHersteller: 'Available manufacturers',
    ausgewaehlteHersteller: 'Selected manufacturers',
    laender: 'Countries',
    verfuegbareLaender: 'Available countries',
    ausgewaehlteLaender: 'Selected countries',
    kunden: 'Customers',
    verfuegbareKunden: 'Available customers',
    ausgewaehlteKunden: 'Selected customers',
    kundenWerdenNichtBeruecksichtigt: 'Selected customers are <b class="text-danger">not</b> included in the evaluation.'
  },
  kundenumsaetze: {
    titel: 'Account Managers > Customers',
    kundenbetreuer: 'Account Manager',
    kundenbetreuerErstellen: 'Create Account Manager',
    kundenbetreuerBearbeiten: 'Edit Account Manager',
    vorname: 'First name',
    nachname: 'Surname',
    businessUnits: 'Business Units',
    verfuegbareBusinessUnits: 'Available Business Units',
    ausgewaehlteBusinessUnits: 'Selected Business Units',
    kunden: 'Customers',
    verfuegbareKunden: 'Available customers',
    ausgewaehlteKunden: 'Selected customers',
    laender: 'Countries'
  },
  amazonumsaetze: {
    titel: 'Amazon > Manufacturers',
    landErstellen: 'Create country',
    landBearbeiten: 'Edit country',
    name: 'Name',
    monatsumsatzziele: 'Monthly sales targets',
    monatsumsatzzieleHinweis: 'The daily sales targets are automatically calculated when saving.',
    monatsumsatzzieleGanzeZahlenHinweis: 'Enter only whole numbers!',
    kunden: 'Customers',
    verfuegbareKunden: 'Available customers',
    ausgewaehlteKunden: 'Selected customers',
    hersteller: 'Manufacturers',
    verfuegbareHersteller: 'Available manufacturers',
    ausgewaehlteHersteller: 'Selected manufacturers',
    laender: 'Countries',
    verfuegbareLaender: 'Available countries',
    ausgewaehlteLaender: 'Selected countries'
  },
  umsatzauswertungTabelle: {
    kunde: 'Customer',
    hersteller: 'Manufacturers',
    umsatzIst: 'Current sales volume (incl. today)',
    umsatzIstHeute: 'Todays sales volume',
    umsatzSoll: 'Target (incl. today)',
    umsatzZielMonat: 'Sales target (month)',
    zielerreichung: 'Target fulfillment (month)'
  },
  wareneingang: {
    titel: 'Incoming goods',
    artikelnummer: 'CL item no.',
    herstellernummer: 'Manuf. item no.',
    bezeichnung: 'Designation',
    weMenge: 'Incoming',
    lager: 'Stock',
    reserviert: 'Reserved',
    frei: 'Free',
    bestellt: 'Ordered'
  },
  lagerwerte: {
    titel: 'Stock of goods',
    name: 'Name',
    stock: 'Stock',
    reserved: 'Reserved',
    ordered: 'Ordered',
    reach: "Extrapolation"
  },
  onTheRoad: {
    titel: 'On the Road',
    herstellernummer: 'Manuf. item no.',
    weMenge: 'On the Road',
    ankunft: 'Arrival'
  },
  generelleEinstellungenHersteller: {
    titel: 'Manufacturers',
    herstellerImportieren: 'Import CSV',
    herstellerExportieren: 'Export CSV',
    herstellerErstellen: 'Create manufacturer',
    herstellerBearbeiten: 'Edit manufacturer',
    name: 'Name',
    lieferantennummern: 'supplier numbers',
    monatsumsatzziele: 'Monthly sales targets',
    monatsumsatzzieleHinweis: 'The daily sales targets are automatically calculated when saving.',
    monatsumsatzzieleGanzeZahlenHinweis: 'Enter only whole numbers!'
  },
  generelleEinstellungenKunden: {
    titel: 'Customers',
    kundenImportieren: 'Import CSV',
    kundenExportieren: 'Export CSV',
    kundeErstellen: 'Create customer',
    kundeBearbeiten: 'Edit customer',
    name: 'Name',
    kundennummer: 'customer number',
    monatsumsatzziele: 'Monthly sales targets',
    monatsumsatzzieleHinweis: 'The daily sales targets are automatically calculated when saving.',
    monatsumsatzzieleGanzeZahlenHinweis: 'Enter only whole numbers!'
  },
  generelleEinstellungenLaender: {
    titel: 'Countries',
    landErstellen: 'Create country',
    landBearbeiten: 'Edit country',
    land: 'Country',
    laendercode: 'Country code',
    laendercodeLink: 'https://en.wikipedia.org/wiki/ISO_3166-1',
    laendercodeHinweis: [
      'Enter the country code according to the ',
      ' ISO 3166-1.',
      ''
    ]
  },
  generelleEinstellungenAjaUpload: {
    titel: 'AJA - On the Road',
  },
  herstellerkundenumsaetze: {
    titel: 'Manufacturers > customers',
    kundenbetreuerBearbeiten: "Edit",
    hersteller: "Manufacturers",
    kunden: "Customers",
    verfuegbareHersteller: "available Producers",
    ausgewaehlteHersteller: "selected Producers",
    verfuegbareKunden: "available Customers",
    ausgewaehlteKunden: "selected Customers",
    umsatzHeute: "Sales Today",
    umsatzMonat: "Sales Month",
    erstellen: "Create",
    bearbeiten: "Edit",
    nurKundenWerdenBeruecksichtigt: "Only selected customers will be used.",
    herstellerkundenumsaetze: 'Manufacturers-Customers sales',
  },
  bukundenherstellerumsaetze: {
    titel: 'BU > Customers > Manufacturers',
    kundenbetreuerBearbeiten: "Edit",
    hersteller: "Manufacturers",
    kunden: "Customers",
    name: "Name",
    verfuegbareHersteller: "available Producers",
    ausgewaehlteHersteller: "selected Producers",
    verfuegbareKunden: "available Customers",
    ausgewaehlteKunden: "selected Customers",
    umsatzHeute: "Sales Today",
    umsatzMonat: "Sales Month",
    erstellen: "Create",
    bearbeiten: "Edit",
    nurKundenWerdenBeruecksichtigt: "Only selected customers will be used.",
    bukundenherstellerumsaetze: 'BU-Customers-Manufacturers sales',
  }
}

export default en_EN