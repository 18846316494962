import Anmelden from "./components/Anmelden.vue";
import Import from "./components/einstellungen/Import.vue";
import NotAllowed from "./components/NotAllowedView.vue";

import Uebersicht from "./components/uebersicht/Uebersicht.vue";

import Wareneingang from "./components/wareneingang/Wareneingang.vue";
import OnTheRoad from "./components/onTheRoad/OnTheRoad.vue";

import BuKundenHerstellerUmsaetze from "./components/bu_kunden_hersteller_umsaetze/BuKundenHerstellerUmsaetze.vue";
import BuKundenHerstellerUmsaetzeReiter from "./components/bu_kunden_hersteller_umsaetze/BuKundenHerstellerUmsaetzeReiter.vue";
import ZeigeBuKundenHerstellerUmsaetze from "./components/bu_kunden_hersteller_umsaetze/edit/ZeigeBuKundenHerstellerUmsaetze.vue";
import ErstelleBuKundenHerstellerUmsaetze from "./components/bu_kunden_hersteller_umsaetze/edit/ErstelleBuKundenHerstellerUmsaetze.vue";
import BearbeiteBuKundenHerstellerUmsaetze from "./components/bu_kunden_hersteller_umsaetze/edit/BearbeiteBuKundenHerstellerUmsaetze.vue";

import Herstellerumsaetze from "./components/herstellerumsaetze/Herstellerumsaetze.vue";
import HerstellerumsaetzeReiter from "./components/herstellerumsaetze/HerstellerumsaetzeReiter.vue";
import ZeigeBusinessUnits from "./components/herstellerumsaetze/businessunits/ZeigeBusinessUnits.vue";
import ErstelleBusinessUnit from "./components/herstellerumsaetze/businessunits/ErstelleBusinessUnit.vue";
import BearbeiteBusinessUnit from "./components/herstellerumsaetze/businessunits/BearbeiteBusinessUnit.vue";

import Kundenumsaetze from "./components/kundenumsaetze/Kundenumsaetze.vue";
import KundenumsaetzeReiter from "./components/kundenumsaetze/KundenumsaetzeReiter.vue";
import ZeigeKundenbetreuer from "./components/kundenumsaetze/kundenbetreuer/ZeigeKundenbetreuer.vue";
import ErstelleKundenbetreuer from "./components/kundenumsaetze/kundenbetreuer/ErstelleKundenbetreuer.vue";
import BearbeiteKundenbetreuer from "./components/kundenumsaetze/kundenbetreuer/BearbeiteKundenbetreuer.vue";

import Amazonumsaetze from "./components/amazonumsaetze/Amazonumsaetze.vue";
import AmazonumsaetzeReiter from "./components/amazonumsaetze/AmazonumsaetzeReiter.vue";
import ZeigeAmazonLaender from "./components/amazonumsaetze/laender/ZeigeAmazonLaender.vue";
import ErstelleAmazonLand from "./components/amazonumsaetze/laender/ErstelleAmazonLand.vue";
import BearbeiteAmazonLand from "./components/amazonumsaetze/laender/BearbeiteAmazonLand.vue";

import Kunden from "./components/einstellungen/kunden/Kunden.vue";
import ZeigeKunden from "./components/einstellungen/kunden/ZeigeKunden.vue";
import ErstelleKunde from "./components/einstellungen/kunden/ErstelleKunde.vue";
import BearbeiteKunde from "./components/einstellungen/kunden/BearbeiteKunde.vue";
import ImportiereKunden from "./components/einstellungen/kunden/ImportiereKunden.vue";

import Hersteller from "./components/einstellungen/hersteller/Hersteller.vue";
import ZeigeHersteller from "./components/einstellungen/hersteller/ZeigeHersteller.vue";
import ErstelleHersteller from "./components/einstellungen/hersteller/ErstelleHersteller.vue";
import BearbeiteHersteller from "./components/einstellungen/hersteller/BearbeiteHersteller.vue";
import ImportiereHersteller from "./components/einstellungen/hersteller/ImportiereHersteller.vue";
import ImportiereAja from "./components/einstellungen/Aja.vue";

import Laender from "./components/einstellungen/laender/Laender.vue";
import ZeigeLaender from "./components/einstellungen/laender/ZeigeLaender.vue";
import ErstelleLand from "./components/einstellungen/laender/ErstelleLand.vue";
import BearbeiteLand from "./components/einstellungen/laender/BearbeiteLand.vue";

import Lagerwerte from "./components/lagerwerte/Lagerwerte.vue";
import BearbeiteLagerHersteller from "./components/lagerwerte/bearbeiten/BearbeiteLagerHersteller.vue";
import LagerwerteTabelle from "./components/lagerwerte/LagerwerteTabelle.vue";

import HerstellerKundenumsaetze from "./components/herstellerumsaetze_kunden/HerstellerKundenumsaetze.vue";
import ZeigeHerstellerKundenumsaetze from "./components/herstellerumsaetze_kunden/edit/ZeigeHerstellerKundenumsaetze.vue";
import ErstelleHerstellerKundenumsaetze from "./components/herstellerumsaetze_kunden/edit/ErstelleHerstellerKundenumsaetze.vue";
import BearbeiteHerstellerKundenumsaetze from "./components/herstellerumsaetze_kunden/edit/BearbeitenHerstellerKundenumsaetze.vue";
import HerstellerKundenumsaetzeReiter from "./components/herstellerumsaetze_kunden/HerstellerKundenumsaetzeReiter.vue";

export default [
  {
    path: "*",
    redirect: "/anmelden",
  },
  {
    path: "/",
    redirect: "/anmelden",
  },
  {
    path: "/token/:token",
    component: Anmelden,
  },
  {
    path: "/anmelden",
    component: Anmelden,
  },
  {
    path: "/notallowed",
    name: "NotAllowed",
    component: NotAllowed,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/uebersicht",
    name: "Uebersicht",
    component: Uebersicht,
    meta: { requiresAuth: true },
  },
  {
    path: "/wareneingang",
    component: Wareneingang,
    meta: { requiresAuth: true },
  },
  {
    path: "/ontheroad",
    component: OnTheRoad,
    meta: { requiresAuth: true },
  },
  {
    path: "/bukundenherstellerumsaetze",
    component: BuKundenHerstellerUmsaetze,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: BuKundenHerstellerUmsaetzeReiter,
        meta: { requiresAuth: true },
      },
      {
        path: "/bukundenherstellerumsaetze/bearbeiten",
        component: ZeigeBuKundenHerstellerUmsaetze,
        meta: { requiresAuth: true },
      },
      {
        path: "/bukundenherstellerumsaetze/erstellen",
        component: ErstelleBuKundenHerstellerUmsaetze,
        meta: { requiresAuth: true },
      },
      {
        path: "/bukundenherstellerumsaetze/bearbeiten/:id",
        component: BearbeiteBuKundenHerstellerUmsaetze,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/herstellerumsaetze",
    component: Herstellerumsaetze,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: HerstellerumsaetzeReiter,
        meta: { requiresAuth: true },
      },
      {
        path: "/herstellerumsaetze/bearbeiten",
        component: ZeigeBusinessUnits,
        meta: { requiresAuth: true },
      },
      {
        path: "/herstellerumsaetze/erstellen",
        component: ErstelleBusinessUnit,
        meta: { requiresAuth: true },
      },
      {
        path: "/herstellerumsaetze/bearbeiten/:id",
        component: BearbeiteBusinessUnit,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/kundenumsaetze",
    component: Kundenumsaetze,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: KundenumsaetzeReiter,
        meta: { requiresAuth: true },
      },
      {
        path: "/kundenumsaetze/bearbeiten",
        component: ZeigeKundenbetreuer,
        meta: { requiresAuth: true },
      },
      {
        path: "/kundenumsaetze/erstellen",
        component: ErstelleKundenbetreuer,
        meta: { requiresAuth: true },
      },
      {
        path: "/kundenumsaetze/bearbeiten/:id",
        component: BearbeiteKundenbetreuer,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/amazonumsaetze",
    component: Amazonumsaetze,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: AmazonumsaetzeReiter,
        meta: { requiresAuth: true },
      },
      {
        path: "/amazonumsaetze/bearbeiten",
        component: ZeigeAmazonLaender,
        meta: { requiresAuth: true },
      },
      {
        path: "/amazonumsaetze/erstellen",
        component: ErstelleAmazonLand,
        meta: { requiresAuth: true },
      },
      {
        path: "/amazonumsaetze/bearbeiten/:id",
        component: BearbeiteAmazonLand,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/einstellungen/kunden",
    component: Kunden,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ZeigeKunden,
        meta: { requiresAuth: true },
      },
      {
        path: "erstellen",
        component: ErstelleKunde,
        meta: { requiresAuth: true },
      },
      {
        path: "bearbeiten/:id",
        component: BearbeiteKunde,
        meta: { requiresAuth: true },
      },
      {
        path: "importieren",
        component: ImportiereKunden,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/einstellungen/hersteller",
    component: Hersteller,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ZeigeHersteller,
        meta: { requiresAuth: true },
      },
      {
        path: "erstellen",
        component: ErstelleHersteller,
        meta: { requiresAuth: true },
      },
      {
        path: "bearbeiten/:id",
        component: BearbeiteHersteller,
        meta: { requiresAuth: true },
      },
      {
        path: "importieren",
        component: ImportiereHersteller,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/einstellungen/laender",
    component: Laender,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ZeigeLaender,
        meta: { requiresAuth: true },
      },
      {
        path: "erstellen",
        component: ErstelleLand,
        meta: { requiresAuth: true },
      },
      {
        path: "bearbeiten/:id",
        component: BearbeiteLand,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/lagerwerte",
    component: Lagerwerte,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: LagerwerteTabelle,
        meta: { requiresAuth: true },
      },
      {
        path: "bearbeiten",
        component: BearbeiteLagerHersteller,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/herstellerkundenumsaetze",
    component: HerstellerKundenumsaetze,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: HerstellerKundenumsaetzeReiter,
        meta: { requiresAuth: true },
      },
      {
        path: "bearbeiten/:id",
        component: BearbeiteHerstellerKundenumsaetze,
        meta: { requiresAuth: true },
      },
      {
        path: "erstellen",
        component: ErstelleHerstellerKundenumsaetze,
        meta: { requiresAuth: true },
      },
      {
        path: "zeige",
        component: ZeigeHerstellerKundenumsaetze,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/einstellungen/import",
    component: Import,
    meta: { requiresAuth: true },
  },
  {
    path: "/einstellungen/aja",
    component: ImportiereAja,
    meta: { requiresAuth: true },
  },
];
