<template>
  <b-navbar
    type="dark"
    variant="primary"
    class="border-right d-flex flex-column align-items-stretch p-0"
    fixed
  >
    <b-navbar-brand
      href="#"
      class="m-0 mt-4 d-flex flex-column align-items-center pb-4"
    >
      <Logo :fill="'#fff'" :height="'42px'" :width="'42px'" class="mb-2" />
      Trademaster
    </b-navbar-brand>
    <div
      class="h-100 d-flex flex-column align-items-stretch justify-content-between pb-3"
    >
      <div>
        <div class="mx-3 text-muted-light text-uppercase">
          <small
            ><small>
              <font-awesome-icon icon="chart-line" fixed-width class="mr-1" />{{
                $t("global.umsatzauswertung")
              }}
            </small></small
          >
        </div>
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link to="/uebersicht" class="nav-link text-muted">
              {{ $t("uebersicht.titel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/herstellerumsaetze" class="nav-link text-muted">
              {{ $t("herstellerumsaetze.titel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/kundenumsaetze" class="nav-link text-muted">
              {{ $t("kundenumsaetze.titel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/amazonumsaetze" class="nav-link text-muted">
              {{ $t("amazonumsaetze.titel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/herstellerkundenumsaetze"
              class="nav-link text-muted"
            >
              {{ $t("herstellerkundenumsaetze.titel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/bukundenherstellerumsaetze"
              class="nav-link text-muted"
            >
              {{ $t("bukundenherstellerumsaetze.titel") }}
            </router-link>
          </li>
        </ul>
        <div
          class="blue-border mx-3 border-top pt-3 mt-3 pb-2 text-muted-light text-uppercase"
        >
          <small
            ><small>
              <font-awesome-icon icon="truck" fixed-width class="mr-1" />{{
                $t("global.sonstiges")
              }}
            </small></small
          >
        </div>
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link to="/lagerwerte" class="nav-link text-muted">
              {{ $t("lagerwerte.titel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/wareneingang" class="nav-link text-muted">
              {{ $t("wareneingang.titel") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/ontheroad" class="nav-link text-muted">
              {{ $t("onTheRoad.titel") }}
            </router-link>
          </li>
        </ul>
      </div>
      <div>
        <ul class="blue-border nav flex-column border-top pt-3">
          <li class="nav-item">
            <a href="#" v-on:click="abmelden" class="nav-link text-muted">
              <font-awesome-icon icon="sign-out" fixed-width class="mr-2" />{{
                $t("anAbmelden.abmelden")
              }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </b-navbar>
</template>

<script>
//Vue Components
import Logo from "../assets/Logo.vue";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";

export default {
  name: "NavbarView",
  components: {
    Logo,
  },
  methods: {
    abmelden: function () {
      signOut(auth)
        .then(() => {
          this.$store.commit("isAdmin", false);
          this.$store.commit("isAdminAja", false);
          this.$router.replace("/")
        })
        .catch((error) => console.error(error.message)); // eslint-disable-line no-console
    },
  },
};
</script>

<style scoped>
.navbar {
  max-width: 250px;
  width: 100%;
  background-color: #4089c9;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1;
}
.nav-link {
  hyphens: auto;
}
.router-link-active {
  border-left: 3px solid #fff;
}
a,
small > small {
  color: rgba(255, 255, 255, 0.788) !important;
}
a:hover {
  color: rgb(255, 255, 255) !important;
}
.blue-border {
  border-color: #5f9cd2 !important;
}
</style>
