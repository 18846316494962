<template>
  <div v-if="umsaetze.length > 0">
    <b-card-body class="border-bottom">
      <filter-list-component @filter="filterList"></filter-list-component>
    </b-card-body>
    <b-table
      class="mb-0"
      striped
      :items="umsaetze"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc="true"
      :filter="filter"
      responsive
      hover
    >
      <template #cell(title)="data">
        <div @click="$emit('tablerowClick', data.item)">{{ data.item.title }}</div>
      </template>
      <template #cell(umsatzIst)="data">
        <div @click="$emit('tablerowClick', data.item)">
          {{ data.item.umsatzIst | formatCurrency }}
        </div>
      </template>
      <template #cell(umsatzIstHeute)="data">
        <div  @click="$emit('tablerowClick', data.item)">
          {{ data.item.umsatzIstHeute | formatCurrency }}
        </div>
      </template>
      <template #custom-foot>
        <b-tr class="border-top" style="border-width: 2px !important">
          <b-th></b-th>
          <b-th>
            {{ summiereUmsaetze(umsaetze) | formatCurrency }}
          </b-th>
          <b-th>
            {{ summiereUmsaetzeHeute(umsaetze) | formatCurrency }}
          </b-th>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import FilterListComponent from "../FilterListComponent.vue";
import { firestore, functions } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";

export default {
  name: "BuKundenHerstellerUmsaetzeTabelle",
  components: {
    FilterListComponent,
  },
  props: {
    kunde: Object,
    selectedDate: String,
  },
  watch: {
    selectedDate(value) {
      this.selectedMonth = moment(value).format("YYYY-MM"); 
      this.fetchData();
    }
  },
  methods: {
    summiereUmsaetze(umsaetze) {
      return umsaetze.reduce((acc, item) => acc + item.umsatzIst, 0);
    },
    summiereUmsaetzeHeute(umsaetze) {
      return umsaetze.reduce((acc, item) => acc + item.umsatzIstHeute, 0);
    },
    tageImMonat() {
      const anzahlTageDesMonats = moment(this.selectedDate).tz('Europe/Berlin').daysInMonth()
      let tageDesMonats = []
      let gesamtWerktageDesMonats = 0

      for (let tag = 1; tag <= anzahlTageDesMonats; tag++) {
        const datum = moment(this.selectedDate).tz('Europe/Berlin').date(tag).format('YYYY-MM-DD')
        const monat = moment(this.selectedDate).tz('Europe/Berlin').format('MM')

        let tagObject = {
          "datum": datum,
          "wochentag": moment(this.selectedDate).tz('Europe/Berlin').date(tag).format('dddd'),
          "feiertag": moment(datum).tz('Europe/Berlin').isHoliday('SH'),
          "werktagDesMonats": 0,
          "gesamtWerktageDesMonats": 0
        }

        if (
          monat === '12' && 
          (tag === 24 || tag === 31)
        ) {
          tagObject.feiertag = 'Interner Feiertag'
        }

        tageDesMonats.push(tagObject)
      }

      let werktagDesMonats = 1

      tageDesMonats.forEach(tagObject => {
        
        if (
          tagObject.wochentag !== 'Saturday' &&
          tagObject.wochentag !== 'Sunday' &&
          !tagObject.feiertag
        ) {
          tagObject.werktagDesMonats = werktagDesMonats++
          gesamtWerktageDesMonats++
        } else {
          tagObject.werktagDesMonats = werktagDesMonats - 1
        } 
      })

      tageDesMonats.forEach(tagObject => {
        tagObject.gesamtWerktageDesMonats = gesamtWerktageDesMonats
      })

      return tageDesMonats
    },
    filterList(value) {
      this.filter = value;
    },
    async getHersteller() {
      const firestoreIds = this.kunde.hersteller.map((item) => item.id);
      const promises = firestoreIds.map((id) => getDoc(doc(firestore, "hersteller", id)));

      const results = await Promise.allSettled(promises);
      const dataset = [];

      results.forEach((result) => {
        if (result.value.exists() && result.status === "fulfilled") {
          const docData = result.value.data();

          dataset.push(docData);
        }
      });

      return dataset;
    },
    createQuery(hersteller) {
      const kundennummernString = "'" + this.kunde.kunden[0].data.kundennummern.join("','") + "'";

      const queryArray = hersteller.map((hersteller) => {
        const lieferantennummernString = "'" + hersteller.lieferantennummern.join("','") + "'";

        return `
          SELECT '${hersteller.name}' AS title, SUM(\`Wert\`) As umsatz
          FROM \`clgmbh-trademaster.trademasterData.table*\`
          WHERE \`Datum\` BETWEEN '${this.selectedMonth}-01' AND '${this.selectedDate}'
          AND \`Lieferantennummer\` IN (${lieferantennummernString})
          AND \`Kundennummer\` IN (${kundennummernString})
          AND \`Warengruppe\` NOT IN ('Technik RMA', 'CLEK_GSAnforderung', 'SONDERARTIKEL')
          AND \`Artikelnummer\` != 'B-1000-Acronis'
        `;
      });

      return queryArray.join(" UNION ALL ");
    },
    createQueryHeute(hersteller) {
      const kundennummernString = "'" + this.kunde.kunden[0].data.kundennummern.join("','") + "'";

      const queryArray = hersteller.map((hersteller) => {
        const lieferantennummernString = "'" + hersteller.lieferantennummern.join("','") + "'";

        return `
          SELECT '${hersteller.name}' AS title, SUM(\`Wert\`) As umsatz
          FROM \`clgmbh-trademaster.trademasterData.table*\`
          WHERE \`Datum\` = '${this.selectedDate}'
          AND \`Lieferantennummer\` IN (${lieferantennummernString})
          AND \`Kundennummer\` IN (${kundennummernString})
          AND \`Warengruppe\` NOT IN ('Technik RMA', 'CLEK_GSAnforderung', 'SONDERARTIKEL')
          AND \`Artikelnummer\` != 'B-1000-Acronis'
        `;
      });

      return queryArray.join(" UNION ALL ");
    },
    async fetchData() { 
      const [hersteller] = await Promise.all([
        this.getHersteller(),
      ]);

      if (hersteller.length > 0) {
        const query = this.createQuery(hersteller);
        const queryHeute = this.createQueryHeute(hersteller);
        const queryBigQuery = httpsCallable(functions, "queryBigQuery");
        const result = await queryBigQuery({ query });
        const resultHeute = await queryBigQuery({ query: queryHeute });

        const umsaetze = JSON.parse(result.data.data);
        const umsaetzeHeute = JSON.parse(resultHeute.data.data);

        let final = [];
        if (umsaetze.length > 0) {
          final = umsaetze.map((umsatz) => {
            const passenderHersteller = hersteller.find((hersteller) => hersteller.name === umsatz.title);
            const passenderUmsatzHeute = umsaetzeHeute.find((umsatzHeute) => umsatzHeute.title === umsatz.title);

            umsatz.umsatzziele = passenderHersteller ? passenderHersteller.umsatzziele : null;
            umsatz.kundennummern = this.kunde.kunden[0].data.kundennummern
            umsatz.lieferantennummern = passenderHersteller.lieferantennummern ? passenderHersteller.lieferantennummern : null;
            umsatz.umsatz = umsatz.umsatz === null ? 0 : Number(umsatz.umsatz);
            umsatz.umsatzHeute = passenderUmsatzHeute.umsatz === null ? 0 : Number(passenderUmsatzHeute.umsatz);
            
            return umsatz;
          });

          this.umsaetze = final.map(element => {
            return {
              title: element.title,
              umsatzIst: element.umsatz,
              umsatzIstHeute: element.umsatzHeute,
              extraData: {
                kundennummern: element.kundennummern,
                lieferantennummern: element.lieferantennummern
              }
            }
          })
        }
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      filter: null,
      umsaetze: [],
      sortBy: "umsatzIst",
      selectedMonth: moment().format("YYYY-MM"),
      fields: [
        {
          key: "title",
          label: this.$t("umsatzauswertungTabelle.kunde"),
          sortable: true,
        },
        {
          key: "umsatzIst",
          label: this.$t("umsatzauswertungTabelle.umsatzIst"),
          sortable: true,
        },
        {
          key: "umsatzIstHeute",
          label: this.$t("umsatzauswertungTabelle.umsatzIstHeute"),
          sortable: true,
        },
      ],
    };
  },
};
</script>
<style scoped>
th {
  white-space: nowrap;
}
th.sortierbar {
  cursor: pointer;
}
</style>