<template>
  <div>
    <table class="table m-0" v-if="umsatzdaten">
      <thead>
        <tr>
          <th colspan="7">{{ $t("uebersicht.umsatzMonat.titel") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ $t("uebersicht.umsatzMonat.umsatzIst") }}</td>
          <td
            :class="
              'text-right' +
              istGroesserAls(umsatzdaten.umsatzIst, umsatzdaten.umsatzSoll)
            "
          >
            {{ umsatzdaten.umsatzIst | formatCurrency }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("uebersicht.umsatzMonat.umsatzSoll") }}</td>
          <td class="text-right">
            {{ umsatzdaten.umsatzSoll | formatCurrency }}
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>{{ $t("uebersicht.umsatzMonat.zielFix") }}</td>
          <td class="text-right">{{ umsatzdaten.zielFix | formatCurrency }}</td>
        </tr>
        <tr>
          <td>
            {{ $t("uebersicht.umsatzMonat.zielerfuellungIst") }}<br />
            <small class="text-muted">{{
              $t("uebersicht.umsatzMonat.zielFix")
            }}</small>
          </td>
          <td class="text-right">
            {{ umsatzdaten.zielerfuellungIst | formatPercent }}
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>{{ $t("uebersicht.umsatzMonat.hrVonUmsatzIst") }}</td>
          <td
            :class="
              'text-right' +
              istGroesserAls(umsatzdaten.hrUmsatzIst, umsatzdaten.zielFix)
            "
          >
            {{ umsatzdaten.hrUmsatzIst | formatCurrency }}
          </td>
        </tr>
        <tr>
          <td>
            {{ $t("uebersicht.umsatzMonat.zielerfuellungHr") }}<br />
            <small class="text-muted">{{
              $t("uebersicht.umsatzMonat.zielFix")
            }}</small>
          </td>
          <td class="text-right">
            {{ umsatzdaten.zielerfuellungHr | formatPercent }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { firestore, functions } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";

export default {
  name: "MonatComponent",
  props: {
    businessUnit: Object,
  },
  data() {
    return {
      umsatzdaten: false,
    };
  },
  methods: {
    istGroesserAls: function (a, b) {
      if (a >= b) {
        return " text-success";
      }
      return " text-danger";
    },
    async getDataset(collectionName, fieldName) {
      const firestoreIds = this.businessUnit[collectionName].map(
        (item) => item.id
      );
      const promises = firestoreIds.map((id) =>
        getDoc(doc(firestore, collectionName, id))
      );

      const results = await Promise.allSettled(promises);
      const dataset = [];

      results.forEach((result) => {
        if (result.value.exists() && result.status === "fulfilled") {
          const docData = result.value.data();

          if (docData.kundennummer) {
            docData.kundennummern = [docData.kundennummer];
          }

          dataset.push(docData[fieldName]);
        }
      });

      return dataset;
    },
    createQuery(laendercodes, lieferantennummern, kundennummern) {
      const kundennummernString = "'" + kundennummern.flat().join("','") + "'";
      const laendercodesString = "'" + laendercodes.join("','") + "'";
      const lieferantennummernString =
        "'" + lieferantennummern.flat().join("','") + "'";

      const thisMonth = moment().format("YYYY-MM");
      const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");

      return `
        SELECT SUM(\`Wert\`) As Umsatz
        FROM \`clgmbh-trademaster.trademasterData.table*\`
        WHERE \`Datum\` BETWEEN '${thisMonth}-01' AND '${yesterday}'
        AND \`Lieferantennummer\` IN (${lieferantennummernString})
        AND \`Land\` IN (${laendercodesString})
        AND \`Kundennummer\` NOT IN (${kundennummernString})
        AND \`Warengruppe\` NOT IN ('Technik RMA', 'CLEK_GSAnforderung', 'SONDERARTIKEL')
        AND \`Artikelnummer\` != 'B-1000-Acronis'
      `;
    },
    async getHersteller() {
      const firestoreIds = this.businessUnit.hersteller.map((item) => item.id);
      const promises = firestoreIds.map((id) => getDoc(doc(firestore, "hersteller", id)));

      const results = await Promise.allSettled(promises);
      const dataset = [];

      results.forEach((result) => {
        if (result.value.exists() && result.status === "fulfilled") {
          const docData = result.value.data();
          dataset.push(docData);
        }
      });

      return dataset;
    },
    async getKunden() {
      const firestoreIds = this.businessUnit.kunden.map((item) => item.id);
      const promises = firestoreIds.map((id) => getDoc(doc(firestore, "kunden", id)));

      const results = await Promise.allSettled(promises);
      const dataset = [];

      results.forEach((result) => {
        if (result.value.exists() && result.status === "fulfilled") {
          const docData = result.value.data();

          if (docData.kundennummer) {
            docData.kundennummern = [docData.kundennummer];
          }

          dataset.push(docData.kundennummern);
        }
      });

      return dataset;
    },
    async fetchData() {
      const [hersteller, laendercodes, kundennummern] = await Promise.all([
        this.getHersteller(),
        this.getDataset("laender", "laendercode"),
        this.getKunden(),
      ]);

      const lieferantennummern =  hersteller.map((hersteller) => {
        return hersteller.lieferantennummern;
      });

      const query = this.createQuery(laendercodes, lieferantennummern, kundennummern);

      // console.log(query);

      const queryBigQuery = httpsCallable(functions, "queryBigQuery");
      const result = await queryBigQuery({ query });
      const umsatzMonat = JSON.parse(result.data.data)[0].Umsatz

      const umsatzzieleTag =  hersteller.map((hersteller) => {
        return hersteller.umsatzziele[moment().format("MM")].tag;
      });

      const umsatzzieleTagSumme = umsatzzieleTag.reduce((acc, curr) => acc + curr, 0);

      const umsatzzieleMonat =  hersteller.map((hersteller) => {
        return hersteller.umsatzziele[moment().format("MM")].monat;
      });

      const umsatzzieleMonatSumme = umsatzzieleMonat.reduce((acc, curr) => acc + curr, 0);

      this.umsatzdaten = {
        umsatzIst: Number(umsatzMonat) || 0,
        umsatzSoll: this.$gestern.abgelaufeneTage * Number(umsatzzieleTagSumme),
        zielerfuellungIst: Number(umsatzMonat) === 0 || Number(umsatzzieleMonatSumme) === 0 ? 0 : Number(umsatzMonat) / Number(umsatzzieleMonatSumme) * 100,
        zielerfuellungHr: Number(umsatzMonat) === 0 || Number(umsatzzieleMonatSumme) === 0 ? 0 : (Number(umsatzMonat) / this.$gestern.abgelaufeneTage * this.$gestern.gesamtTage) / Number(umsatzzieleMonatSumme) * 100,
        zielFix: Number(umsatzzieleMonatSumme),
        hrUmsatzIst: Number(umsatzMonat) === 0 ? 0 :  Number(umsatzMonat) / this.$gestern.abgelaufeneTage * this.$gestern.gesamtTage,
      };

      console.log(this.umsatzdaten);
    },
  },
  async mounted() {
    this.fetchData();
  },
};
</script>
