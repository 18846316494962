<template>
  <button class="btn btn-primary" v-on:click="importFile">Import</button>
</template>

<script>
// Moment
import moment from 'moment'
import 'moment-feiertage'

import {firestore } from "../../../firebase"
import {updateDoc, doc } from "firebase/firestore"

export default {
  name: 'KundenImportierenComponent',
  props: {
    customers: Array
  },
  methods: {
    importFile: function() {
      let promises = []
      this.customers.forEach(customer => {
        const data = {
          umsatzziele: {
            '01': { 'monat': customer.january, 'tag': this.berechneTagesziel(customer.january, '01') },
            '02': { 'monat': customer.february, 'tag': this.berechneTagesziel(customer.february, '02') },
            '03': { 'monat': customer.march, 'tag': this.berechneTagesziel(customer.march, '03') },
            '04': { 'monat': customer.april, 'tag': this.berechneTagesziel(customer.april, '04') },
            '05': { 'monat': customer.may, 'tag': this.berechneTagesziel(customer.may, '05') },
            '06': { 'monat': customer.june, 'tag': this.berechneTagesziel(customer.june, '06') },
            '07': { 'monat': customer.july, 'tag': this.berechneTagesziel(customer.july, '07') },
            '08': { 'monat': customer.august, 'tag': this.berechneTagesziel(customer.august, '08') },
            '09': { 'monat': customer.september, 'tag': this.berechneTagesziel(customer.september, '09') },
            '10': { 'monat': customer.october, 'tag': this.berechneTagesziel(customer.october, '10') },
            '11': { 'monat': customer.november, 'tag': this.berechneTagesziel(customer.november, '11') },
            '12': { 'monat': customer.december, 'tag': this.berechneTagesziel(customer.december, '12') }
          }
        }
        promises.push(updateDoc(doc(firestore, 'kunden', customer.id), data))
      })
      Promise.all(promises)
      .then(() => {
        this.$emit('importDone')
      })
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    berechneTagesziel: function(monatsziel, monat) {
      if (monatsziel === 0) {
        return 0
      }
      const jahr = moment().year()
      const tageInMonat = moment(jahr + '-' + monat, 'YYYY-MM').daysInMonth()
      let werktageInMonat = 0
      for (let tag = 1; tag <= tageInMonat; tag++) {
        const wochentag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).format('dddd')
        let feiertag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).isHoliday('SH')
        if (
          monat === '12' && 
          (tag === 24 || tag === 31)
        ) {
          feiertag = 'Interner Feiertag'
        }
        if (
          wochentag !== 'Saturday' &&
          wochentag !== 'Sunday' &&
          feiertag === false
        ) {
          werktageInMonat++
        }
      }
      return +parseFloat(monatsziel / werktageInMonat).toFixed(2)
    }
  }
}
</script>