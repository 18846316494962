<template>
  <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner></b-spinner></div>

  <div class="container-fluid p-4" v-else>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
          <small class="text-muted mb-1 ">
            <font-awesome-icon icon="cog" fixed-width class="mr-1" />{{$t("global.ansichtBearbeiten")}}: {{$t("amazonumsaetze.titel")}}
          </small>
          <h1 class="h2">{{$t("amazonumsaetze.landBearbeiten")}}</h1>
        </div>

        <form v-on:submit.prevent="validieren">
          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t('amazonumsaetze.name')}}</label>
              <b-input v-model="name" placeholder="Amazon DE" required></b-input>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label mb-3">{{$t("amazonumsaetze.kunden")}}</label>
              <draggable-component 
                :group="'kunden'"
                :itemName="'name'"
                :availableItems="verfuegbareKunden"
                :selectedItems="ausgewaehlteKunden"
                :availableItemsHeading="$t('amazonumsaetze.verfuegbareKunden')"
                :selectedItemsHeading="$t('amazonumsaetze.ausgewaehlteKunden')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label mb-3">{{$t("amazonumsaetze.hersteller")}}</label>
              <draggable-component 
                :group="'hersteller'"
                :itemName="'name'"
                :availableItems="verfuegbareHersteller"
                :selectedItems="ausgewaehlteHersteller"
                :availableItemsHeading="$t('amazonumsaetze.verfuegbareHersteller')"
                :selectedItemsHeading="$t('amazonumsaetze.ausgewaehlteHersteller')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label mb-3">{{$t("amazonumsaetze.laender")}}</label>
              <draggable-component 
                :group="'laender'"
                :itemName="'land'"
                :availableItems="verfuegbareLaender"
                :selectedItems="ausgewaehlteLaender"
                :availableItemsHeading="$t('amazonumsaetze.verfuegbareLaender')"
                :selectedItemsHeading="$t('amazonumsaetze.ausgewaehlteLaender')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-button type="submit" variant="primary" block>{{$t("global.speichern")}}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment-feiertage'
import DraggableComponent from '../../DraggableComponent.vue'

import { firestore } from "../../../firebase"
import {  orderBy, collection, doc, getDoc, setDoc,getDocs, query } from "firebase/firestore"

export default {
  name: 'BearbeiteAmazonLand',
  components: { DraggableComponent },
  async mounted() {
    this.laden()
  },
  methods: {
    laden: function() {
      const firestorePromises = [
        getDoc(doc(firestore, "amazonLaender", this.$route.params.id)),
        getDocs(query(collection(firestore, "kunden"), orderBy("slug", "asc"))),
        getDocs(query(collection(firestore, "hersteller"), orderBy("slug", "asc"))),
        getDocs(query(collection(firestore, "laender"), orderBy("slug", "asc")))
      ]

      Promise.all(firestorePromises)
      .then(response => {
        const amazonLaenderSnapshot = response[0]
        if (amazonLaenderSnapshot.exists) {
          this.name = amazonLaenderSnapshot.data().name
          this.orderIndex = amazonLaenderSnapshot.data().orderIndex
          this.umsatzzielJanuar = amazonLaenderSnapshot.data().umsatzziele['01'].monat
          this.umsatzzielFebruar = amazonLaenderSnapshot.data().umsatzziele['02'].monat
          this.umsatzzielMaerz = amazonLaenderSnapshot.data().umsatzziele['03'].monat
          this.umsatzzielApril = amazonLaenderSnapshot.data().umsatzziele['04'].monat
          this.umsatzzielMai = amazonLaenderSnapshot.data().umsatzziele['05'].monat
          this.umsatzzielJuni = amazonLaenderSnapshot.data().umsatzziele['06'].monat
          this.umsatzzielJuli = amazonLaenderSnapshot.data().umsatzziele['07'].monat
          this.umsatzzielAugust = amazonLaenderSnapshot.data().umsatzziele['08'].monat
          this.umsatzzielSeptember = amazonLaenderSnapshot.data().umsatzziele['09'].monat
          this.umsatzzielOktober = amazonLaenderSnapshot.data().umsatzziele['10'].monat
          this.umsatzzielNovember = amazonLaenderSnapshot.data().umsatzziele['11'].monat
          this.umsatzzielDezember = amazonLaenderSnapshot.data().umsatzziele['12'].monat
          this.ausgewaehlteKunden = amazonLaenderSnapshot.data().kunden
          this.ausgewaehlteHersteller = amazonLaenderSnapshot.data().hersteller
          this.ausgewaehlteLaender = amazonLaenderSnapshot.data().laender
        } else {
          this.$bvModal.msgBoxOk(this.$t('global.datensatzExistiertNicht'), {
            title: '',
            okVariant: 'primary',
            okTitle: this.$t('global.ok'),
            hideHeaderClose: false,
            noCloseOnBackdrop: true
          })
          .then(() => this.$router.replace('/amazonumsaetze/bearbeiten'))
          .catch(error => console.error(error)) // eslint-disable-line no-console
        }

        const kundenSnapshot = response[1]
        kundenSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name
            }
          }
          this.verfuegbareKunden.push(element)
        })

        const herstellerSnapshot = response[2]
        herstellerSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name
            }
          }
          this.verfuegbareHersteller.push(element)
        })

        const laenderSnapshot = response[3]
        laenderSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              land: doc.data().land
            }
          }
          this.verfuegbareLaender.push(element)
        })

        this.verfuegbareKunden = this.filterNotSelectedItems(this.verfuegbareKunden, this.ausgewaehlteKunden)
        this.verfuegbareHersteller = this.filterNotSelectedItems(this.verfuegbareHersteller, this.ausgewaehlteHersteller)
        this.verfuegbareLaender = this.filterNotSelectedItems(this.verfuegbareLaender, this.ausgewaehlteLaender)
      })
      .then(() => this.isLoading = false)
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    filterNotSelectedItems(availableItems, selectedItems) {
      return availableItems.filter(availableItem => {
        const selectedItemIds = selectedItems.map(selectedItem => selectedItem.id)
        if (selectedItemIds.includes(availableItem.id)) {
          return false
        }
        return true
      })
    },
    validieren: function() {
      if (
        this.name === '' ||
        this.umsatzzielJanuar === '' ||
        this.umsatzzielFebruar === '' ||
        this.umsatzzielMaerz === '' ||
        this.umsatzzielApril === '' ||
        this.umsatzzielMai === '' ||
        this.umsatzzielJuni === '' ||
        this.umsatzzielJuli === '' ||
        this.umsatzzielAugust === '' ||
        this.umsatzzielSeptember === '' ||
        this.umsatzzielOktober === '' ||
        this.umsatzzielNovember === '' ||
        this.umsatzzielDezember === ''
      ) {
        this.$bvModal.msgBoxOk(this.$t('global.alleFelderAusfuellen'), {
          title: '',
          okVariant: 'primary',
          okTitle: this.$t('global.ok'),
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        })
        .catch(error => console.error(error)) // eslint-disable-line no-console
        return
      }
      this.speichern()
    },
    speichern: function() {
      const umsatzziele = {
        '01': { 'monat': this.umsatzzielJanuar, 'tag': this.berechneTagesziel(this.umsatzzielJanuar, '01') },
        '02': { 'monat': this.umsatzzielFebruar, 'tag': this.berechneTagesziel(this.umsatzzielFebruar, '02') },
        '03': { 'monat': this.umsatzzielMaerz, 'tag': this.berechneTagesziel(this.umsatzzielMaerz, '03') },
        '04': { 'monat': this.umsatzzielApril, 'tag': this.berechneTagesziel(this.umsatzzielApril, '04') },
        '05': { 'monat': this.umsatzzielMai, 'tag': this.berechneTagesziel(this.umsatzzielMai, '05') },
        '06': { 'monat': this.umsatzzielJuni, 'tag': this.berechneTagesziel(this.umsatzzielJuni, '06') },
        '07': { 'monat': this.umsatzzielJuli, 'tag': this.berechneTagesziel(this.umsatzzielJuli, '07') },
        '08': { 'monat': this.umsatzzielAugust, 'tag': this.berechneTagesziel(this.umsatzzielAugust, '08') },
        '09': { 'monat': this.umsatzzielSeptember, 'tag': this.berechneTagesziel(this.umsatzzielSeptember, '09') },
        '10': { 'monat': this.umsatzzielOktober, 'tag': this.berechneTagesziel(this.umsatzzielOktober, '10') },
        '11': { 'monat': this.umsatzzielNovember, 'tag': this.berechneTagesziel(this.umsatzzielNovember, '11') },
        '12': { 'monat': this.umsatzzielDezember, 'tag': this.berechneTagesziel(this.umsatzzielDezember, '12') }
      }

      setDoc(doc(firestore, "amazonLaender", this.$route.params.id), { 
        name: this.name,
        orderIndex: this.orderIndex,
        slug: this.name.toLowerCase(),
        umsatzziele: umsatzziele,
        kunden: this.ausgewaehlteKunden,
        hersteller: this.ausgewaehlteHersteller,
        laender: this.ausgewaehlteLaender
      })
      .then(() => this.$router.replace('/amazonumsaetze/bearbeiten'))
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    berechneTagesziel: function(monatsziel, monat) {
      if (monatsziel === 0) {
        return 0
      }

      const jahr = moment().year()
      const tageInMonat = moment(jahr + '-' + monat, 'YYYY-MM').daysInMonth()

      let werktageInMonat = 0

      for (let tag = 1; tag <= tageInMonat; tag++) {
        const wochentag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).format('dddd')
        let feiertag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).isHoliday('SH')

        if (
          monat === '12' && 
          (tag === 24 || tag === 31)
        ) {
          feiertag = 'Interner Feiertag'
        }

        if (
          wochentag !== 'Saturday' &&
          wochentag !== 'Sunday' &&
          feiertag === false
        ) {
          werktageInMonat++
        }
      }

      return +parseFloat(monatsziel / werktageInMonat).toFixed(2)
    }
  },
  data() {
    return {
      isLoading: true,
      name: '',
      orderIndex: 999,
      umsatzzielJanuar: 0,
      umsatzzielFebruar: 0,
      umsatzzielMaerz: 0,
      umsatzzielApril: 0,
      umsatzzielMai: 0,
      umsatzzielJuni: 0,
      umsatzzielJuli: 0,
      umsatzzielAugust: 0,
      umsatzzielSeptember: 0,
      umsatzzielOktober: 0,
      umsatzzielNovember: 0,
      umsatzzielDezember: 0,
      verfuegbareHersteller: [],
      ausgewaehlteHersteller: [],
      verfuegbareLaender: [],
      ausgewaehlteLaender: [],
      verfuegbareKunden: [],
      ausgewaehlteKunden: []
    }
  }
}
</script>
