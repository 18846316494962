<template>
  <b-card class="shadow-sm ml-4" id="chart-box">
    <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner variant="primary"></b-spinner></div>
    <b-card-body v-else>
      <p class="font-weight-bold">{{ selectedTableRow.title }}</p>
      <div id="chart">
        <apexchart-vue
          :options="chartData"
          :series="chartData.series"
        ></apexchart-vue>
      </div>
      <div id="data-list">
        <div
          v-for="(element, index) in umsaetze"
          :key="`item-${index}`"
          class="d-flex justify-content-between border-bottom py-2"
        >
          <div>{{ element.Month | formatDate }}</div>
          <div>{{ element.Umsatz | formatCurrency }}</div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export default {
  name: "HerstellerKundenumsaetzeChartbox",
  props: {
    selectedTableRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      umsaetze: [],
      isLoading: true,
      chartData: {
        chart: {
          height: 600,
          type: "bar",
          legend: {
            show: false,
          },
          toolbar: {
            show: false,
          },
          redrawOnWindowResize: true,
        },
        yaxis: {
          labels: {
            show: false,
            formatter: (value) => {
              return this.$options.filters.formatCurrency(value);
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    selectedTableRow() {
      this.isLoading = true;
      this.fetchData();
    },
    umsaetze(value) {
      this.chartData.series = [
        {
          name: "Umsatz",
          data: value.map((element) => ({
            x: this.$options.filters.formatDate(element.Month),
            y: element.Umsatz,
            label: "$" + element.Umsatz.toLocaleString(),
          })),
        },
      ];
    },
  },
  methods: {
    createQuery(kundennummern, lieferantennummern) {
      const kundennummernString = "'" + kundennummern.flat().join("','") + "'";
      const lieferantennummernString = "'" + lieferantennummern.join("','") + "'";

      return `
        SELECT
        FORMAT_TIMESTAMP('%Y-%m', DATE_TRUNC(DATE(\`Datum\`), MONTH)) AS Month,
        SUM(\`Wert\`) AS Umsatz
        FROM
        \`clgmbh-trademaster.trademasterData.table*\`
        WHERE
        \`Datum\` >= DATE_SUB(CURRENT_DATE(), INTERVAL 11 MONTH)
          AND \`Datum\` <= CURRENT_DATE()
          AND \`Lieferantennummer\` IN (${lieferantennummernString})
          AND \`Kundennummer\` IN (${kundennummernString})
          AND \`Warengruppe\` NOT IN ('Technik RMA', 'CLEK_GSAnforderung', 'SONDERARTIKEL')
          AND \`Artikelnummer\` != 'B-1000-Acronis'
        GROUP BY
          Month
        ORDER BY
          Month DESC
      `;
    },
    async fetchData() {
      try {
        const query = this.createQuery(
          this.selectedTableRow.extraData.kundennummern,
          this.selectedTableRow.extraData.lieferantennummern
        );
        
        const queryBigQuery = httpsCallable(functions, "queryBigQuery");
        const result = await queryBigQuery({ query });
        this.umsaetze = JSON.parse(result.data.data) || [];
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
#data-list > div:last-child {
  border-bottom: none !important;
}
#chart-box {
  width: 380px;
}
</style>
