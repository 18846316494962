import Vue from 'vue'
import VueI18n from 'vue-i18n'
import de_DE from './de_DE.js'
import en_EN from './en_EN.js'

Vue.use(VueI18n)

const messages = {
  de_DE,
  en_EN
}

const i18n = new VueI18n({
  locale: 'de_DE',
  messages
})

export default i18n