<template>
  <div v-if="businessUnit.produkte !== undefined">
    <b-card-body class="border-bottom">
      <filter-list-component v-on:filter="filterList"></filter-list-component>
    </b-card-body>
    <b-table striped :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :sort-compare="customCompare">
      <template #cell(arrivalDate)="data">
        {{ data.item.arrivalDate }}
      </template>
    </b-table>
  </div>
  <div class="alert alert-warning text-center m-3" v-else><font-awesome-icon icon="info-circle" /> {{$t("global.keineDatenVorhanden")}}</div>
</template>

<script>
import FilterListComponent from '../FilterListComponent.vue'
import moment from 'moment'

export default {
  name: 'OnTheRoadTabelle',
  components: {
    FilterListComponent,
  },
  props: {
    businessUnit: Object,
  },
  computed: {
    items() {
      let newArr = []
      this.businessUnit.produkte.forEach((produkt)=> {
        let index = newArr.findIndex(element => element.partNr === produkt.partNr && element.arrivalDate === produkt.arrivalDate)
        if(index < 0){
          newArr.push(produkt)
        } else {
          newArr[index].qty = newArr[index].qty + produkt.qty
        }
      })
      newArr.forEach((data, key) =>{
        newArr[key].arrivalDate = this.add7Days(data.arrivalDate)
      })
      return newArr
    }
  },
  methods: {
    filterList(value) {
      this.filter = value
    },
    customCompare(itemA, itemB, key) {
      if (key !== 'arrivalDate') {
        return false
      } else {
        let a = itemA[key]
        let b = itemB[key]
        a = moment(a, 'DD.MM.YYYY').format('X')
        b = moment(b, 'DD.MM.YYYY').format('X')
        return a - b
      }
    },
    add7Days(date){
      let add7 = moment(date, 'DD.MM.YYYY').add(7, 'days')
      return add7.format('DD.MM.YYYY')
    }
  },
  data() {
    return {
      filter: null,
      sortBy: 'arrivalDate',
      sortDesc: true,
      fields: [
        {
          key: 'partNr',
          label: this.$t("onTheRoad.herstellernummer"),
          sortable: true,
        },
        {
          key: 'qty',
          label: this.$t("onTheRoad.weMenge"),
          sortable: true,
        },
        {
          key: 'arrivalDate',
          label: this.$t("onTheRoad.ankunft"),
          sortable: true,
        }
      ],
    }
  }
}
</script>

<style>
th {
  white-space: nowrap !important;
}
.card-costom-style .card-body{
  padding: 0 !important;
}
</style>
