<template>
  <div>
    <div class="pb-4 d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <small class="text-muted mb-1">
          <font-awesome-icon icon="truck" fixed-width class="mr-1" />{{$t("global.sonstiges")}}
        </small>
        <h1 class="h2">{{$t("lagerwerte.titel")}}</h1>
      </div>
      <router-link
        to="/lagerwerte/bearbeiten"
        class="btn btn-primary align-self-center"
        v-show="$store.state.isAdmin === 'true' || $store.state.isAdmin"
      >
        <font-awesome-icon icon="cog" class="mr-2" />{{ $t("global.ansichtBearbeiten") }}
      </router-link>
    </div>
    <div v-if="lagerwerte !== undefined">
      <div class="card">
        <b-table class="mb-0" striped :items="lagerwerte" :fields="fields" :sort-by.sync="sortBy" :sort-desc="true" :filter="filter" responsive>
          <template v-slot:cell(name)="data"><b><span :style="!isNaN(data.item.name) && 'color: red;'">{{ data.item.name }}</span></b></template>
          <template v-slot:cell(stock)="data"><b>{{ data.item.stock | formatCurrency }}</b></template>
          <template v-slot:cell(reserved)="data">{{ data.item.reserved | formatCurrency }}</template>
          <template v-slot:cell(ordered)="data">{{ data.item.ordered | formatCurrency }}</template>
          <template v-slot:cell(reach)="data">{{ data.item.reach != "Infinity" ? Number(data.item.reach.toFixed(2)).toLocaleString('de-DE') : "" }}</template>
        </b-table>
      </div>
    </div>
    <div class="alert alert-warning text-center mb-0" v-else><font-awesome-icon icon="info-circle" /> {{$t("global.keineDatenVorhanden")}}</div>
  </div>
</template>

<script>

export default {
  name: 'LagerwerteTabelle',
  methods: {
    filterList(value) {
      this.filter = value
    }
  },
  data() {
    return {
      filter: null,
      lagerwerte: [],
      sortBy: 'stock',
      fields: [
        {
          key: 'name',
          label: this.$t("lagerwerte.name"),
          sortable: true,
        },
        {
          key: 'stock',
          label: this.$t("lagerwerte.stock"),
          sortable: true,
        },
        {
          key: 'reserved',
          label: this.$t("lagerwerte.reserved"),
          sortable: true,
        },
        {
          key: 'ordered',
          label: this.$t("lagerwerte.ordered"),
          sortable: true,
        },
        {
          key: 'reach',
          label: this.$t("lagerwerte.reach"),
          sortable: true,
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('fetchLagerwerte')
    .then(() => {
      this.lagerwerte = this.$store.state.lagerwerte
    })
  }
}
</script>

<style scoped>
th {
  white-space: nowrap;
}
th.sortierbar {
  cursor: pointer;
}
</style>