import moment from "moment-timezone";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const formatPercent = (value) => {
  if (value === undefined) return;
  
  const formattedValue = value.toFixed(1).replace(".", ",");
  
  if (value === 0 || value === 100 || formattedValue.endsWith(",0")) {
    return `${formattedValue.replace(",0", "")} %`;
  }
  
  return `${formattedValue} %`;
};

const formatDate = (value) => {
  return moment(value).locale("DE").format("MMMM YYYY");
};

export { formatCurrency, formatPercent, formatDate };
