import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// Firebase 
import { auth, db, firestore } from '../firebase.js'
import { onValue, ref } from "firebase/database"
import { query, getDocs, collection } from "firebase/firestore"
export default new Vuex.Store({
  state: {
    wareneingang: [],
    onTheRoad:[],
    lagerwerte: [],
    auftragseingang: [],
    datum: '28.01.2020',
    letzteAktualisierung: '00:00',
    isAdmin: false,
    isAdminAja: false
  },
  mutations: {
    datum(state, datum) {
      state.datum = datum
    },
    wareneingang(state, wareneingang) {
      state.wareneingang = wareneingang
    },
    onTheRoad(state, onTheRoad) {
      state.onTheRoad = onTheRoad
    },
    lagerwerte(state, lagerwerte) {
      state.lagerwerte = lagerwerte
    },
    auftragseingang(state, auftragseingang) {
      state.auftragseingang = auftragseingang
    },
    isAdmin(state, isAdmin) {
      state.isAdmin = isAdmin
    },
    isAdminAja(state, isAdminAja) {
      state.isAdminAja = isAdminAja
    }
  },
  actions: {
    setDatum({commit}, datum) {
      commit('datum', datum)
    },
    fetchWareneingang({commit}) {
      return new Promise(resolve => {
        onValue(ref(db, 'snapshots/wareneingang'), snapshot => {
          if (snapshot.val() !== null) {
            commit('wareneingang', snapshot.val())
          }
          console.log('fetchWareneingang done') // eslint-disable-line no-console
          resolve()
        })
      })
    },
    fetchOnTheRoad({commit}) {
      return new Promise(resolve => {
        onValue(ref(db, `uploads/onTheRoad`), snapshot => {
          if (snapshot.val() !== null) {
            commit('onTheRoad', snapshot.val())
          }
          console.log('fetchonTheRoad done') // eslint-disable-line no-console
          resolve()
        })
      })
    },
    async fetchLagerwerte({commit}) {
      const q = query(collection(firestore, "lagerwerte"));
      let data = []
      const snap = await getDocs(q);
      snap.forEach((doc) => {
        data.push(doc.data())
      });
      commit('lagerwerte', data)
      console.log('fetchLagerwerte done') // eslint-disable-line no-console
      return
    },
    fetchAuftragseingang({commit}) {
      return new Promise(resolve => {
        onValue(ref(db, 'snapshots/auftragseingang'), snapshot => {
          if (snapshot.val() !== null) {
            commit('auftragseingang', snapshot.val())
          }
          console.log('fetchAuftragseingang done') // eslint-disable-line no-console
          resolve()
        })
      })
    },
    fetchIsAdmin: async function({commit}) {
      const benutzer = auth.currentUser
      if (benutzer != null) {
        const claims = await benutzer.getIdTokenResult()

        claims.claims.admin = claims.claims.admin || false
        claims.claims.adminAja = claims.claims.adminAja || false

        commit('isAdmin', claims.claims.admin)
        commit('isAdminAja', claims.claims.adminAja)
      }
    }
  }
})
