<template>
  <div id="change-order-component">
    <button class="btn btn-secondary mr-2" v-b-modal="'change-order-modal'">
      <font-awesome-icon icon="sort" class="mr-2" />{{ $t("global.reihenfolgeAendern") }}
    </button>
    <b-modal
      id="change-order-modal"
      ref="change-order-modal"
      :title="$t('global.reihenfolgeAendern')"
      no-close-on-backdrop
      hide-header-close
      content-class="shadow"
      body-class="p-0"
      scrollable
      :size="size"
    >
      <table class="table m-0">
        <draggable v-model="entries" tag="tbody">
          <tr v-for="entry in entries" :key="`entry-${entry.id}`" v-html="rowTds(entry.data)"></tr>
        </draggable>
      </table>

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">{{ $t("global.abbrechen") }}</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from "./SaveButtonComponent";
import draggable from "vuedraggable";
import _ from "lodash";
import { firestore } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

export default {
  name: "ChangeOrderComponent",
  components: {
    SaveButtonComponent,
    draggable,
  },
  props: {
    entriesProp: Array,
    collection: String,
    size: String,
  },
  methods: {
    rowTds: function (data) {
      if (this.collection === "kundenbetreuer") return `<td>${data.vorname} ${data.nachname}</td>`;
      else if (this.collection === "businessunits") return `<td>${data.name}</td>`;
      else if (this.collection === "amazonLaender") return `<td>${data.name}</td>`;
      else return "<td></td>";
    },
    hideModal: function () {
      this.$refs["change-order-modal"].hide();
    },
    saveChanges: async function () {
      this.saveButtonSate = "saving";
      const promises = [];

      this.entries.forEach((entry, index) => {
        entry.data.orderIndex = index;
        promises.push(updateDoc(doc(firestore, this.collection, entry.id), entry.data));
      });

      await Promise.all(promises)
        .then(() => {
          this.$emit("orderChanged", this.entries);
          this.saveButtonSate = "done";
          setTimeout(() => this.hideModal(), 1800);
        })
        .catch(() => {
          this.saveButtonSate = "error";
          setTimeout(() => (this.saveButtonSate = ""), 1800);
        });
    },
    orderByIndex: function (data) {
      return _.orderBy(data, (entry) => entry.data.orderIndex, "asc");
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", () => {
      this.entries = this.orderByIndex(this.entriesProp);
    });
    this.$root.$on("bv::modal::hidden", () => {
      this.saveButtonSate = "";
    });
  },
  data() {
    return {
      entries: [],
      saveButtonSate: "",
    };
  },
};
</script>

<style>
table tr:first-of-type td {
  border-top: 0 !important;
}
table tr td:hover {
  cursor: grab;
}
table tr.sortable-chosen td:hover {
  cursor: grabbing !important;
}
</style>
