import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import { auth } from "./firebase";
import routes from "./routes";

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await auth.currentUser;
  const getIdTokenResult = currentUser
    ? await currentUser.getIdTokenResult()
    : false;
  const isUser = getIdTokenResult ? getIdTokenResult.claims.user : false;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (
    (to.path === "/anmelden" || to.name === "NotAllowed") &&
    currentUser &&
    isUser
  ) {
    next("uebersicht");
  } else if (
    to.name !== "NotAllowed" &&
    !isUser &&
    currentUser &&
    requiresAuth
  ) {
    next({ name: "NotAllowed" });
  } else if (requiresAuth && currentUser) {
    next();
  } else if (requiresAuth && !currentUser) {
    next("anmelden");
  } else {
    next();
  }
});

export default router;
