<template>
  <b-navbar toggleable="lg" type="light" variant="light" id="top-navbar" class="border-bottom">

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown class="mr-2" right v-if="isAdmin || isAdminAja">
          <template #button-content>
            <font-awesome-icon icon="cogs" fixed-width class="text-body" />
          </template>
          <b-dropdown-item to="/einstellungen/hersteller" v-if="isAdmin">{{ $t("generelleEinstellungenHersteller.titel") }}</b-dropdown-item>
          <b-dropdown-item to="/einstellungen/kunden" v-if="isAdmin">{{ $t("generelleEinstellungenKunden.titel") }}</b-dropdown-item>
          <b-dropdown-item to="/einstellungen/laender" v-if="isAdmin">{{ $t("generelleEinstellungenLaender.titel") }}</b-dropdown-item>
          <b-dropdown-item to="/einstellungen/aja" v-if="isAdminAja">{{ $t("generelleEinstellungenAjaUpload.titel") }}</b-dropdown-item>
        </b-nav-item-dropdown>
        <Sprachenauswahl />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Sprachenauswahl from "./Sprachenauswahl.vue";

export default {
  name: 'TopNavbar',
  components: {
    Sprachenauswahl,
  },
  data() {
    return {
      isAdmin: false,
      isAdminAja: false,
    }
  },
  watch: {
    "$store.state.isAdmin": function(val) {
      this.isAdmin = val;
    },
    "$store.state.isAdminAja": function(val) {
      this.isAdminAja = val;
    },
  },
}
</script>

<style>
#content {
  margin-left: 250px;
  display: flex;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main-label {
  font-weight: 700;
}
.text-muted-light {
  color: #8a929a !important;
}
[id$="BV_tab_controls_"] {
  padding: 1rem !important;
  border-right: 1px solid #dee2e6;
}
.table th {
	border-top: none !important;
}
.custom-file-input:lang(de) ~ .custom-file-label::after {
  content: 'Durchsuchen' !important;
}
.mx-datepicker-main.mx-datepicker-popup {
  left: 50% !important;
  transform: translateX(-50%);
}
th, td, h1 {
  color: #5c5c5c;
}
</style>
