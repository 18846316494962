<template>
  <div class="container-fluid p-4">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
          <small class="text-muted mb-1">
            <font-awesome-icon icon="cogs" fixed-width class="mr-1" />{{$t("global.generelleEinstellungen")}}
          </small>
          <h1 class="h2">{{$t("generelleEinstellungenKunden.titel")}}-{{$t("generelleEinstellungenKunden.kundenImportieren")}}</h1>
        </div>

        <b-form-file
          accept=".csv"
          @change="change"
          placeholder="Datei auswählen oder hierher ziehen und ablegen"
          drop-placeholder="Datei hier ablegen"
          ref="fileInputCustomers">
        </b-form-file>
        
        <div v-if="customers.length > 0">
          <div class="alert alert-warning mt-4">
            Bitte überprüfe vor dem Import, ob die Daten so korrekt sind.
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>Kunde</th>
                <th>Januar</th>
                <th>Februar</th>
                <th>März</th>
                <th>April</th>
                <th>Mai</th>
                <th>Juni</th>
                <th>Juli</th>
                <th>August</th>
                <th>September</th>
                <th>Oktober</th>
                <th>November</th>
                <th>Dezember</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(customerElement, customerIndex) in customers" :key="`customer-${customerIndex}`">
                <td>{{customerElement.customer}}</td>
                <td>{{customerElement.january}}</td>
                <td>{{customerElement.february}}</td>
                <td>{{customerElement.march}}</td>
                <td>{{customerElement.april}}</td>
                <td>{{customerElement.may}}</td>
                <td>{{customerElement.june}}</td>
                <td>{{customerElement.july}}</td>
                <td>{{customerElement.august}}</td>
                <td>{{customerElement.september}}</td>
                <td>{{customerElement.october}}</td>
                <td>{{customerElement.november}}</td>
                <td>{{customerElement.december}}</td>
              </tr>
            </tbody>
          </table>
          <KundenImportierenComponent :customers="customers" v-on:importDone="importDone" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KundenImportierenComponent from './KundenImportierenComponent'
import Papa from 'papaparse'

export default {
  name: 'ImportiereKunden',
  components: {
    KundenImportierenComponent
  },
  methods: {
    change: function(event) {
      if (event.type === 'change') {
        this.parseFile(event.target.files[0])
      }
      if (event.type === 'drop') {
        this.parseFile(event.dataTransfer.files[0])
      }
    },
    parseNumber(value) {
      if (!Number.isInteger(value)) {
        const decNumber = value.replace(',', '.')
        return Math.round(decNumber)
      }
      return value
    },
    parseFile: function(file) {
      const fileReader = new FileReader()
      fileReader.readAsText(file)
      fileReader.onload = e => {
        if (e.target.result !== '') {
          const csvData = Papa.parse(e.target.result, { 
            delimiter: ';',
            dynamicTyping: true,
            header: true
          })
          this.customers = []
          csvData.data.forEach(row => {
            const data = {
              id: row.id,
              customer: row.kunde,
              january: this.parseNumber(row.januar),
              february: this.parseNumber(row.februar),
              march: this.parseNumber(row.maerz),
              april: this.parseNumber(row.april),
              may: this.parseNumber(row.mai),
              june: this.parseNumber(row.juni),
              july: this.parseNumber(row.juli),
              august: this.parseNumber(row.august),
              september: this.parseNumber(row.september),
              october: this.parseNumber(row.oktober),
              november: this.parseNumber(row.november),
              december: this.parseNumber(row.dezember)
            }
            this.customers.push(data)
          })
        }
      }
    },
    importDone: function() {
      this.$refs['fileInputCustomers'].reset()
      this.customers = []
      alert('Import abgeschlossen')
    }
  },
  data() {
    return {
      file: null,
      customers: []
    }
  }
}
</script>
