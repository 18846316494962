<template>
  <div class="m-auto d-flex flex-column align-items-center text-muted" v-if="isLoading"><b-spinner></b-spinner></div>

  <div class="container-fluid p-4" v-else>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="pb-4 d-flex flex-column justify-content-between border-bottom mb-4">
          <small class="text-muted mb-1 ">
            <font-awesome-icon icon="cog" fixed-width class="mr-1" />{{$t("global.ansichtBearbeiten")}}: {{$t("kundenumsaetze.titel")}}
          </small>
          <h1 class="h2">{{$t("kundenumsaetze.kundenbetreuerErstellen")}}</h1>
        </div>

        <form v-on:submit.prevent="validieren">
          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t("kundenumsaetze.vorname")}}</label>
              <b-input v-model="vorname" placeholder="Max" required></b-input>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label">{{$t("kundenumsaetze.nachname")}}</label>
              <b-input v-model="nachname" placeholder="Mustermann" required></b-input>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label mb-3">{{$t("kundenumsaetze.businessUnits")}}</label>
              <draggable-component 
                :group="'business-units'"
                :itemName="'name'"
                :availableItems="verfuegbareBusinessUnits"
                :selectedItems="ausgewaehlteBusinessUnits"
                :availableItemsHeading="$t('kundenumsaetze.verfuegbareBusinessUnits')"
                :selectedItemsHeading="$t('kundenumsaetze.ausgewaehlteBusinessUnits')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-form-group>
            <b-card class="shadow-sm">
              <label class="main-label mb-3">{{$t("kundenumsaetze.kunden")}}</label>
              <draggable-component 
                :group="'customers'"
                :itemName="'name'"
                :availableItems="verfuegbareKunden"
                :selectedItems="ausgewaehlteKunden"
                :availableItemsHeading="$t('kundenumsaetze.verfuegbareKunden')"
                :selectedItemsHeading="$t('kundenumsaetze.ausgewaehlteKunden')">
              </draggable-component>
            </b-card>
          </b-form-group>

          <b-button type="submit" variant="primary" block>{{$t("global.speichern")}}</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DraggableComponent from '../../DraggableComponent.vue'

import { firestore } from "../../../firebase"
import { query, orderBy, addDoc, getDocs, collection} from "firebase/firestore"

export default {
  name: 'ErstelleKundenbetreuer',
  components: { DraggableComponent },
  mounted() {
    this.laden()
  },
  methods: {
    laden: function() {
      const firestorePromises = [
        getDocs(query(collection(firestore, "kunden"), orderBy("slug", "asc"))),
        getDocs(query(collection(firestore, "businessunits"), orderBy("slug", "asc")))
      ]

      Promise.all(firestorePromises)
      .then(response => {
        const kundenSnapshot = response[0]
        kundenSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name
            }
          }
          this.verfuegbareKunden.push(element)
        })

        const businessUnitsSnapshot = response[1]
        businessUnitsSnapshot.forEach(doc => {
          const element = {
            id: doc.id,
            data: {
              name: doc.data().name
            }
          }
          this.verfuegbareBusinessUnits.push(element)
        })
      })
      .then(() => this.isLoading = false)
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    validieren: function() {
      if (
        this.vorname === '' || 
        this.nachname === ''
      ) {
        this.$bvModal.msgBoxOk(this.$t("global.alleFelderAusfuellen"), {
          title: '',
          okVariant: 'primary',
          okTitle: this.$t("global.ok"),
          hideHeaderClose: false,
          noCloseOnBackdrop: true
        })
        .catch(error => console.error(error)) // eslint-disable-line no-console
        return
      }
      this.speichern()
    },
    speichern: function() {
      addDoc(collection(firestore, "kundenbetreuer"), { 
        vorname: this.vorname, 
        nachname: this.nachname,
        orderIndex: 999,
        slug: `${this.vorname.toLowerCase()}${this.nachname.toLowerCase()}`,
        kunden: this.ausgewaehlteKunden,
        businessUnits: this.ausgewaehlteBusinessUnits
      })
      .then(() => this.$router.replace('/kundenumsaetze/bearbeiten'))
      .catch(error => console.error(error)) // eslint-disable-line no-console
    }
  },
  data() {
    return {
      isLoading: true,
      vorname: '',
      nachname: '',
      verfuegbareKunden: [],
      ausgewaehlteKunden: [],
      verfuegbareBusinessUnits: [],
      ausgewaehlteBusinessUnits: []
    }
  }
}
</script>
