<template>
  <div class="d-flex w-100 h-100">
    <Navbar />
    <div id="content" class="flex-grow-1 bg-light">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from "../Navbar.vue";

export default {
  name: "AmazonumsaetzeComponent",
  components: {
    Navbar,
  },
};
</script>
