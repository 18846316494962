<template>
  <div class="d-flex w-100 h-100">
    <Navbar />
    <div id="content" class="flex-grow-1 bg-light">
      <div class="container-fluid p-4 position-relative bg-light">
        <div class="pb-4 d-flex flex-column justify-content-between">
          <small class="text-muted mb-1 "><font-awesome-icon icon="chart-line" fixed-width class="mr-1" />{{$t("global.umsatzauswertung")}}</small>
          <h1 class="h2">{{$t("uebersicht.titel")}}</h1>
        </div>
        <b-card no-body class="shadow-sm">
          <b-tabs pills card vertical>
            <b-tab no-body :title="element.name" v-for="(element, index) in orderByIndex(businessUnits)" :key="`tab-${index}`">
              <div class="row">
                <div class="col">
                  <MonatComponent v-if="isLoading === false" :businessUnit="element" />
                </div>
                <div class="col">
                  <HeuteComponent v-if="isLoading === false" :businessUnit="element" />
                </div>
                <div class="col">
                  <DiverseComponent v-if="isLoading === false" :businessUnit="element" />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../Navbar.vue'
import MonatComponent from './MonatComponent.vue'
import HeuteComponent from './HeuteComponent.vue'
import DiverseComponent from './DiverseComponent.vue'
import _ from 'lodash'

import { firestore } from "../../firebase"
import { orderBy, query, collection, getDocs } from "firebase/firestore"

export default {
  name: 'UebersichtComponent',
  components: {
    Navbar,
    MonatComponent,
    HeuteComponent,
    DiverseComponent
  },
  async mounted() {
    const querySnapshot = await getDocs(query(collection(firestore, "businessunits"), orderBy("slug", "asc")))

    querySnapshot.forEach(doc => {
      this.businessUnits.push(doc.data())
    })

    await this.$store.dispatch('fetchAuftragseingang')

    this.isLoading = false
  },
  created() {
    this.$store.dispatch("fetchIsAdmin");
  },
  methods: {
    orderByIndex: function(data) {
      return _.orderBy(data, (entry) => entry.orderIndex, 'asc')
    },
  },
  data() {
    return {
      businessUnits: [],
      isLoading: true
    }
  }
}
</script>