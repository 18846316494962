<template>
  <div class="d-flex w-100 h-100">
    <Navbar />
    <div id="content" class="flex-grow-1 bg-light">
      <div class="container-fluid p-4 position-relative">
        <div class="pb-4 d-flex justify-content-between align-items-center">
          <div class="d-flex flex-column">
            <small class="text-muted mb-1">
              <font-awesome-icon icon="truck" fixed-width class="mr-1" />{{$t("global.sonstiges")}}
            </small>
            <h1 class="h2">{{$t("onTheRoad.titel")}}</h1>
          </div>
        </div>
        <b-card no-body class="shadow-sm">
          <b-tabs pills card vertical>
            <b-tab no-body :title="element.name" v-for="(element, index) in onTheRoad" :key="`tab-${index}`">
              <OnTheRoadTabelle :businessUnit="element" />
            </b-tab> 
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../Navbar.vue'
import OnTheRoadTabelle from './OnTheRoadTabelle.vue'
import _ from 'lodash'

export default {
  name: 'OnTheRoad',
  components: {
    Navbar,
    OnTheRoadTabelle
  },
  mounted() {
    this.$store.dispatch('fetchOnTheRoad')
    .then(() => {
      const onTheRoadSortiert = _.sortBy(this.$store.state.onTheRoad, ['name'])
      this.onTheRoad = onTheRoadSortiert
    })
  },
  data() {
    return {
      onTheRoad: []
    }
  }
}
</script>