const de_DE = {
  global: {
    reihenfolgeAendern: 'Sortierung ändern',
    bitteWarten: 'Bitte warten',
    erledigt: 'Erledigt',
    fehler: 'Fehler',
    umsatzauswertung: 'Umsatzauswertung',
    generelleEinstellungen: 'Generelle Einstellungen',
    sonstiges: 'Sonstiges',
    keineDatenVorhanden: 'Es sind keine Daten vorhanden.',
    hinweis: 'Es werden alle Umsätze vom 1. des Monats bis zum gewählten Tag des Monats angezeigt.',
    ansichtBearbeiten: 'Ansicht bearbeiten',
    monate: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    speichern: 'Speichern',
    wirklichLoeschen: [
      'Soll der Datensatz ',
      ' wirklich gelöscht werden?'
    ],
    alleFelderAusfuellen: 'Bitte fülle alle Felder aus um fortzufahren.',
    diesesFeldAusfuellen: 'Bitte fülle das Feld aus um fortzufahren.',
    datensatzExistiertNicht: 'Dieser Datensatz existiert nicht.',
    ok: 'OK',
    abbrechen: 'Abbrechen',
    suche: 'Suche'
  },
  anAbmelden: {
    titel: 'Anmeldung',
    anmelden: 'Anmelden',
    abmelden: 'Abmelden',
    email: 'Deine E-Mail-Adresse',
    passwort: 'Dein Passwort',
    sprache: 'Sprache',
    ungeueltigeEmail: 'Die E-Mail-Adresse ist ungültig.',
    keinPasswort: 'Kein Passwort eingegeben.',
    ungeueltigesPasswort: 'Das Passwort ist ungültig.',
    benutzerNichtGefunden: 'Es existiert kein Benutzerdatensatz, der dieser Kennung entspricht.',
    benutzerDeaktiviert: 'Das Benutzerkonto wurde von einem Administrator deaktiviert.'
  },
  uebersicht: {
    titel: 'Übersicht',
    umsatzMonat: {
      titel: 'Umsatz Monat (einschl. gestern)',
      umsatzIst: 'Umsatz IST',
      umsatzSoll: 'Umsatz SOLL (Linear)',
      hrVonUmsatzIst: 'Forecast (HR)',
      zielFix: 'Forecast (Fix)',
      zielerfuellungHr: 'Zielerfüllung (HR)',
      zielerfuellungIst: 'Zielerfüllung',
      zielerfuellungSoll: 'Zielerfüllung (SOLL)'
    },
    umsatzHeute: {
      titel: 'Umsatz Heute',
      umsatzIst: 'Umsatz IST',
      umsatzSoll: 'Umsatz SOLL (Linear)',
      zielerfuellungTag: 'Zielerfüllung',
      tageszielHrMonat: 'Umsatz SOLL (HR)'
    },
    auftraegeHeute: {
      titel: 'Aufträge Heute',
      auftraegeAnzahl: 'Anzahl',
      auftragswert: 'Wert (Sell-in)'
    }
  },
  herstellerumsaetze: {
    titel: 'Business Units > Hersteller',
    businessUnits: 'Business Units',
    buErstellen: 'Business Unit erstellen',
    buBearbeiten: 'Business Unit bearbeiten',
    name: 'Name',
    monatsumsatzziele: 'Monatsumsatzziele',
    monatsumsatzzieleHinweis: 'Die Tagesumsatzziele werden beim Speichern automatisch berechnet.',
    monatsumsatzzieleGanzeZahlenHinweis: 'Nur ganze Zahlen eintragen!',
    hersteller: 'Hersteller',
    verfuegbareHersteller: 'Verfügbare Hersteller',
    ausgewaehlteHersteller: 'Ausgewählte Hersteller',
    laender: 'Länder',
    verfuegbareLaender: 'Verfügbare Länder',
    ausgewaehlteLaender: 'Ausgewählte Länder',
    kunden: 'Kunden',
    verfuegbareKunden: 'Verfügbare Kunden',
    ausgewaehlteKunden: 'Ausgewählte Kunden',
    kundenWerdenNichtBeruecksichtigt: 'Ausgewählte Kunden werden in der Auswertung <b class="text-danger">nicht</b> berücksichtigt.'
  },
  kundenumsaetze: {
    titel: 'Kundenbetreuer > Kunden',
    kundenbetreuer: 'Kundenbetreuer',
    kundenbetreuerErstellen: 'Kundenbetreuer erstellen',
    kundenbetreuerBearbeiten: 'Kundenbetreuer bearbeiten',
    vorname: 'Vorname',
    nachname: 'Nachname',
    businessUnits: 'Business Units',
    verfuegbareBusinessUnits: 'Verfügbare Business Units',
    ausgewaehlteBusinessUnits: 'Ausgewählte Business Units',
    kunden: 'Kunden',
    verfuegbareKunden: 'Verfügbare Kunden',
    ausgewaehlteKunden: 'Ausgewählte Kunden',
    laender: 'Länder'
  },
  amazonumsaetze: {
    titel: 'Amazon > Hersteller',
    landErstellen: 'Land erstellen',
    landBearbeiten: 'Land bearbeiten',
    name: 'Name',
    monatsumsatzziele: 'Monatsumsatzziele',
    monatsumsatzzieleHinweis: 'Die Tagesumsatzziele werden beim Speichern automatisch berechnet.',
    monatsumsatzzieleGanzeZahlenHinweis: 'Nur ganze Zahlen eintragen!',
    kunden: 'Kunden',
    verfuegbareKunden: 'Verfügbare Kunden',
    ausgewaehlteKunden: 'Ausgewählte Kunden',
    hersteller: 'Hersteller',
    verfuegbareHersteller: 'Verfügbare Hersteller',
    ausgewaehlteHersteller: 'Ausgewählte Hersteller',
    laender: 'Länder',
    verfuegbareLaender: 'Verfügbare Länder',
    ausgewaehlteLaender: 'Ausgewählte Länder'
  },
  umsatzauswertungTabelle: {
    kunde: 'Kunde',
    hersteller: 'Hersteller',
    umsatzIst: 'Umsatz IST (einschl. heute)',
    umsatzIstHeute: 'Heutiger Umsatz',
    umsatzSoll: 'Umsatz SOLL (einschl. heute)',
    umsatzZielMonat: 'Umsatz Ziel (Monat)',
    zielerreichung: 'Zielerreichung (Monat)'
  },
  wareneingang: {
    titel: 'Wareneingang',
    artikelnummer: 'Art. Nr.',
    herstellernummer: 'Herst. Art. Nr.',
    bezeichnung: 'Bezeichnung',
    weMenge: 'WE Menge',
    lager: 'Lager',
    reserviert: 'Reserviert',
    frei: 'Frei',
    bestellt: 'Bestellt'
  },
  lagerwerte: {
    titel: 'Warenbestand',
    name: 'Name',
    stock: 'Lagernd',
    reserved: 'Reserviert',
    ordered: 'Bestellt',
    reach: "Hochrechnung"
  },
  onTheRoad: {
    titel: 'On the Road',
    herstellernummer: 'Herst. Art. Nr.',
    weMenge: 'On the Road',
    ankunft: 'Ankunft'
  },
  generelleEinstellungenHersteller: {
    titel: 'Hersteller',
    herstellerImportieren: 'CSV importieren',
    herstellerExportieren: 'CSV exportieren',
    herstellerErstellen: 'Hersteller erstellen',
    herstellerBearbeiten: 'Hersteller bearbeiten',
    name: 'Name',
    lieferantennummern: 'Lieferantennummern',
    monatsumsatzziele: 'Monatsumsatzziele',
    monatsumsatzzieleHinweis: 'Die Tagesumsatzziele werden beim Speichern automatisch berechnet.',
    monatsumsatzzieleGanzeZahlenHinweis: 'Nur ganze Zahlen eintragen!'
  },
  generelleEinstellungenKunden: {
    titel: 'Kunden',
    kundenImportieren: 'CSV importieren',
    kundenExportieren: 'CSV exportieren',
    kundeErstellen: 'Kunde erstellen',
    kundeBearbeiten: 'Kunde bearbeiten',
    name: 'Name',
    kundennummer: 'Kundennummer',
    monatsumsatzziele: 'Monatsumsatzziele',
    monatsumsatzzieleHinweis: 'Die Tagesumsatzziele werden beim Speichern automatisch berechnet.',
    monatsumsatzzieleGanzeZahlenHinweis: 'Nur ganze Zahlen eintragen!'
  },
  generelleEinstellungenLaender: {
    titel: 'Länder',
    landErstellen: 'Land erstellen',
    landBearbeiten: 'Land bearbeiten',
    land: 'Land',
    laendercode: 'Ländercode',
    laendercodeLink: 'https://de.wikipedia.org/wiki/ISO-3166-1-Kodierliste',
    laendercodeHinweis: [
      'Den Ländercode entsprechend der ',
      'ISO 3166 Kodierliste',
      ' eintragen.'
    ]
  },
  generelleEinstellungenAjaUpload: {
    titel: 'AJA - On the Road',
  },
  herstellerkundenumsaetze: {
    titel: 'Hersteller > Kunden',
    kundenbetreuerBearbeiten: "Bearbeiten",
    hersteller: "Hersteller",
    kunden: "Kunden",
    name: "Name",
    verfuegbareHersteller: "verfügbare Hersteller",
    ausgewaehlteHersteller: "ausgewählte Hersteller",
    verfuegbareKunden: "verfügbare Customers",
    ausgewaehlteKunden: "ausgewählte Customers",
    umsatzHeute: "Umsatz Heute",
    umsatzMonat: "Umsatz Monat (inkl. Heute)",
    erstellen: "Erstellen",
    bearbeiten: "Bearbeiten",
    nurKundenWerdenBeruecksichtigt: "Nur ausgewählte Kunden werden berücksichtigt.",
    herstellerkundenumsaetze: 'Hersteller-Kunden Umsätze',
  },
  bukundenherstellerumsaetze: {
    titel: 'BU > Kunden > Hersteller',
    kundenbetreuerBearbeiten: "Bearbeiten",
    hersteller: "Hersteller",
    kunden: "Kunden",
    name: "Name",
    verfuegbareHersteller: "verfügbare Hersteller",
    ausgewaehlteHersteller: "ausgewählte Hersteller",
    verfuegbareKunden: "verfügbare Customers",
    ausgewaehlteKunden: "ausgewählte Customers",
    umsatzHeute: "Umsatz Heute",
    umsatzMonat: "Umsatz Monat (inkl. Heute)",
    erstellen: "Erstellen",
    bearbeiten: "Bearbeiten",
    nurKundenWerdenBeruecksichtigt: "Nur ausgewählte Kunden werden berücksichtigt.",
    bukundenherstellerumsaetze: 'BU > Kunden > Hersteller Umsätze',
  }
}

export default de_DE