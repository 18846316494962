<template>
  <button class="btn btn-primary" v-on:click="importFile">Import</button>
</template>

<script>
// Moment
import moment from 'moment'
import 'moment-feiertage'
import { firestore } from "../../../firebase"
import { updateDoc, doc } from "firebase/firestore"

export default {
  name: 'HerstellerImportierenComponent',
  props: {
    suppliers: Array
  },
  methods: {
    importFile: function() {
      let promises = []
      this.suppliers.forEach(supplier => {
        const data = {
          umsatzziele: {
            '01': { 'monat': supplier.january, 'tag': this.berechneTagesziel(supplier.january, '01') },
            '02': { 'monat': supplier.february, 'tag': this.berechneTagesziel(supplier.february, '02') },
            '03': { 'monat': supplier.march, 'tag': this.berechneTagesziel(supplier.march, '03') },
            '04': { 'monat': supplier.april, 'tag': this.berechneTagesziel(supplier.april, '04') },
            '05': { 'monat': supplier.may, 'tag': this.berechneTagesziel(supplier.may, '05') },
            '06': { 'monat': supplier.june, 'tag': this.berechneTagesziel(supplier.june, '06') },
            '07': { 'monat': supplier.july, 'tag': this.berechneTagesziel(supplier.july, '07') },
            '08': { 'monat': supplier.august, 'tag': this.berechneTagesziel(supplier.august, '08') },
            '09': { 'monat': supplier.september, 'tag': this.berechneTagesziel(supplier.september, '09') },
            '10': { 'monat': supplier.october, 'tag': this.berechneTagesziel(supplier.october, '10') },
            '11': { 'monat': supplier.november, 'tag': this.berechneTagesziel(supplier.november, '11') },
            '12': { 'monat': supplier.december, 'tag': this.berechneTagesziel(supplier.december, '12') }
          }
        }
        promises.push(updateDoc(doc(firestore, "hersteller", supplier.id), data))
      })
      Promise.all(promises)
      .then(() => {
        this.$emit('importDone')
      })
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    berechneTagesziel: function(monatsziel, monat) {
      if (monatsziel === 0) {
        return 0
      }
      const jahr = moment().year()
      const tageInMonat = moment(jahr + '-' + monat, 'YYYY-MM').daysInMonth()
      let werktageInMonat = 0
      for (let tag = 1; tag <= tageInMonat; tag++) {
        const wochentag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).format('dddd')
        let feiertag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).isHoliday('SH')
        if (
          monat === '12' && 
          (tag === 24 || tag === 31)
        ) {
          feiertag = 'Interner Feiertag'
        }
        if (
          wochentag !== 'Saturday' &&
          wochentag !== 'Sunday' &&
          feiertag === false
        ) {
          werktageInMonat++
        }
      }
      return +parseFloat(monatsziel / werktageInMonat).toFixed(2)
    }
  }
}
</script>