<template>
  <div class="p-5">
    <button type="button" class="btn btn-dark mr-3" v-on:click="loadCouch">Exportiere Daten von CouchDB ({{exportCount}})</button>
    <button type="button" class="btn btn-primary" v-on:click="speichern">Importiere Daten in Firestore ({{importCount}})</button>
  </div>
</template>

<script>
// Moment
import moment from 'moment'
import 'moment-feiertage'

import axios from 'axios'

import {firestore} from "../../firebase"
import {setDoc, doc} from "firebase/firestore"

export default {
  name: 'ImportComponent',
  data() {
    return {
      couchData: [],
      exportCount: 0,
      importCount: 0
    }
  },
  methods: {
    loadCouch: function() {
      axios.get(`https://couchdb.comline-shop.de/trademastermanufacturers/_all_docs?include_docs=true`)
      .then(response => {
        response.data.rows.forEach(element => {
          if (element.id !== '_design/trademastermanufacturers') {
            const data = { 
              name: element.doc.hersteller,
              slug: element.doc.hersteller.toLowerCase(),
              lieferantennummern: element.doc.lieferantennummern,
              umsatzziele: {
                '01': { 'monat': element.doc.umsatzziele.januar.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.januar.monatsziel, '01') },
                '02': { 'monat': element.doc.umsatzziele.februar.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.februar.monatsziel, '02') },
                '03': { 'monat': element.doc.umsatzziele.maerz.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.maerz.monatsziel, '03') },
                '04': { 'monat': element.doc.umsatzziele.april.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.april.monatsziel, '04') },
                '05': { 'monat': element.doc.umsatzziele.mai.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.mai.monatsziel, '05') },
                '06': { 'monat': element.doc.umsatzziele.juni.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.juni.monatsziel, '06') },
                '07': { 'monat': element.doc.umsatzziele.juli.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.juli.monatsziel, '07') },
                '08': { 'monat': element.doc.umsatzziele.august.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.august.monatsziel, '08') },
                '09': { 'monat': element.doc.umsatzziele.september.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.september.monatsziel, '09') },
                '10': { 'monat': element.doc.umsatzziele.oktober.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.oktober.monatsziel, '10') },
                '11': { 'monat': element.doc.umsatzziele.november.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.november.monatsziel, '11') },
                '12': { 'monat': element.doc.umsatzziele.dezember.monatsziel, 'tag': this.berechneTagesziel(element.doc.umsatzziele.dezember.monatsziel, '12') }
              }
            }
            this.couchData.push(data)
            this.exportCount++
          }
        })
      })
      .catch(error => console.error(error)) // eslint-disable-line no-console
    },
    speichern: function() {
      this.couchData.forEach(element => {
        setDoc(doc(firestore, "hersteller") ,element)
        .then(() => this.importCount++)
        .catch(error => console.error(error)) // eslint-disable-line no-console
      })
    },
    berechneTagesziel: function(monatsziel, monat) {
      if (monatsziel === 0) {
        return 0
      }

      const jahr = moment().year()
      const tageInMonat = moment(jahr + '-' + monat, 'YYYY-MM').daysInMonth()

      let werktageInMonat = 0

      for (let tag = 1; tag <= tageInMonat; tag++) {
        const wochentag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).format('dddd')
        const feiertag = moment(jahr + '-' + monat, 'YYYY-MM').date(tag).isHoliday('SH')

        if (
          wochentag !== 'Saturday' &&
          wochentag !== 'Sunday' &&
          feiertag === false
        ) {
          werktageInMonat++
        }
      }

      return +parseFloat(monatsziel / werktageInMonat).toFixed(2)
    }
  }
}
</script>
